<template>
  <div>
    <header>
      <h3 class="logo"><a href="http://www.fruits5.co.kr" target="_blank"><img src="@/assets/images/logo.png" alt="로고"></a></h3>
      <button v-if="isMobile" class="widget-mylocation-button" @click="mylocation()" alt="내위치">
        <div :class="myLocationCls"></div>
        <span style="margin-left: 15px">내위치</span>
      </button>
    </header>
    <div class="container">
      <div class="banner">
        <div class="link_btn_sel">
          <div class="link_box">
            <p>우리동네</p>
            <p class="green m_font">국가검진 병원</p>
            <div class="sel_text green">
              <!--<div v-if="isMobile && screenWidth <= 980" :class="myLocationCls" @click.stop.prevent="mylocation()"></div>-->
              {{ siGunGuNm }}
              <img src="@/assets/images/btn_sel.png" alt="화살표" />
              <div class="link_sel">
                <a v-if="addrLevel ===2 && addrs.length > 0 && addrs[0].siDoCd === undefined" class="link_btn back" href="#" @click.prevent="selectAddr(-1)"><div class="arrow">&#8629;</div> 시도</a>
                <a
                  v-for="(addr, i) in addrs"
                  :key="i"
                  href="#"
                  @click.prevent="selectAddr(i)"
                  :class="i === selectedAddrIdx ? 'link_btn active' : 'link_btn'">
                  {{addr.text}}
                </a>
              </div>
            </div>
            <p class="green pc_font">국가검진 병원</p>
          </div>

        </div>
        <p>우리동네의 국민건강보험공단에서 시행하는 국가건강검진 지정병원을 안내해 드립니다.</p>
        <div class="option">
          <template v-for="(item, index) in searchOptions.items">
            <div v-if="item.selected == true" class="select active">{{item.text}}<span class="close_btn" @click="removeFilter(index)">X</span></div>
            <div v-else-if="item.child !== null" class="select_pop_btn" @click="item.showChild = !item.showChild">
              <p>{{item.text}}</p><span class="close_btn">X</span>
              <div :class="item.showChild === true ? 'select_pop animated fadeInUpShort view' : 'select_pop animated fadeInUpShort'">
                <template v-for="(subItem, subIndex) in item.child">
                  <input class="input_chk" v-bind:id="'box'+(subIndex+1)" type="checkbox" :value="subIndex" v-model="item.subSettings"/>
                  <label :for="'box'+(subIndex+1)" class="link_btn">{{subItem.text}}</label>
                </template>
                <button type="button" class="select_pop_close" @click="setFilter(index, true)" :disabled="item.subSettings.length === 0">적용</button>
              </div>
            </div>
            <div v-else class="select" @click="setFilter(index, false)">{{item.text}}<span class="close_btn">X</span></div>
          </template>
          <div class="search" @click="openFindAppointment('')">예약조회</div>
        </div>
      </div>
      <div class="list_wrap">
        <div class="left_content">
          <div v-if="!shot" id="sticky_top" class="top">
            <div class="m_calendar_head">
              <swiper
                class="main_cal2"
                ref="mCalHeadSlide"
                @slide-change="mCalHeadSlideChange"
                :options="swiperMCalHeadOption"
              >
                <swiper-slide v-for="(head) in mCalendarHeads">
                  <p @click="openCalendar()"><img src="@/assets/images/calen.svg" alt="" width="20px" /> {{ $moment(head.date).format('MM/DD(dd') }}{{(head.diff < 3) ? ',' : ''}}<b v-if="head.diff < 3" class="green">{{ head.diff == 1 ? '내일' : '모레'}}</b>)</p>
                </swiper-slide>
              </swiper>
              <div class="swiper_btn_box swiper-button-prev_3" slot="button-prev"><font-awesome-icon icon="fa-solid fa-chevron-left" /></div>
              <div class="swiper_btn_box swiper-button-next_3" slot="button-next"><font-awesome-icon icon="fa-solid fa-chevron-right" /></div>
            </div>
            <p v-if="hlink" class="num">
            </p>
            <p v-else class="num">
                <span class="green">{{total}} </span>개의 병원이 있습니다.
            </p>
            <p v-if="!hlink" class="m_num green">{{total}}개 결과</p>
            <div v-if="!isMobile" class="calendar_head">
              <swiper
                class="main_cal"
                ref="calHeadSlide"
                :options="swiperCalHeadOption"
                @slide-change="calHeadSlideChange"
              >
                <!--:options="swiperTopOption"-->
                <swiper-slide v-for="(head) in calendarHeads" >
                  {{head.year + '. ' + padLeft(String(head.month),2)}}
                </swiper-slide>

                <div class="swiper_btn_box swiper-button-prev_2" slot="button-prev"><font-awesome-icon icon="fa-solid fa-chevron-left" /></div>
                <div class="swiper_btn_box swiper-button-next_2" slot="button-next"><font-awesome-icon icon="fa-solid fa-chevron-right" /></div>

              </swiper>

              <div class="week">
                <p>일</p>
                <p>월</p>
                <p>화</p>
                <p>수</p>
                <p>목</p>
                <p>금</p>
                <p>토</p>
              </div>
            </div>
          </div>
          <div
            v-for="(item, index) in checkups"
            :class="boost === item.hmcNo ? 'bottom active' : 'bottom'" :style="index === 0 ? 'border-top:none;' : ''"
          >
            <div :id= "'checkup' + index" class="left_content" @mouseover="onLeftContentOver(item.id, true)" @mouseout="onLeftContentOver(item.id, false)">
              <div class="company_img">
                <a v-if="item.active && item.hmcTpImg && item.hmcHomeUrl" class="img img1" :style="item.hmcTpImg" :href="item.hmcHomeUrl" target="_blank">
                  <!--<div class="search_icon"></div>-->
                </a>
                <div v-else-if="item.active && item.hmcTpImg" class="img img1" :style="item.hmcTpImg">
                </div>
                <div v-else :class="item.exmdChrg.aImgCls">
                  <p v-html="item.exmdChrg.aImgHtml"></p>
                </div>
                <!--<p v-if="item.holidayExmd" class="desc">휴일진료</p>-->
              </div>
              <div class="company_desc">
                <p v-if="index === 0 && hmcNo === item.hmcNo" class="dec">최근 방문하셨던 병원</p>
                <div class="overflow">
                  <h3>{{item.hmcNm}}</h3>
                  <div class="icon_box">
                    <a v-if="item.blogUrl" class="blog_off" :href="item.blogUrl" target="_blank"></a>
                    <!--<a v-else class="blog_off"></a>-->
                    <a v-if="item.cafeUrl" class="cafe_off" :href="item.cafeUrl" target="_blank"></a>
                    <!--<a v-else class="cafe_off"></a>-->
                    <a v-if="item.kakaoUrl" class="kakao_off" :href="item.kakaoUrl" target="_blank"></a>
                    <!--<a v-else class="kakao_off"></a>-->
                    <a v-if="item.instaUrl" class="insta_off" :href="item.instaUrl" target="_blank"></a>
                    <!--<a v-else class="insta_off"></a>-->
                  </div>
                </div>
                <p><span class="yellow">{{item.exmdChrg.str}}</span></p>
                <p class="singleline-ellipsis">{{item.clinicalSubject}}</p>
                <div class="address">
                  <div v-if="item.lon != 0 && item.lat != 0">
                    <div class="label">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 18" class="addr-label-ico" role="presentation"><path d="M15 6.97a6.92 6.92 0 0 1-1.12 3.77l-5.51 7.08a.47.47 0 0 1-.74 0L2.1 10.71A6.93 6.93 0 0 1 1 6.97 7 7 0 0 1 8 0v.93V0a7 7 0 0 1 7 6.97zm-13 0c0 1.15.4 2.3.99 3.24L8 16.7l5.04-6.5A5.95 5.95 0 0 0 8 1C4.66 1 2 3.64 2 6.97zm6-1.54A1.58 1.58 0 0 0 8 8.6a1.57 1.57 0 0 0 0-3.16zm0-.93a2.51 2.51 0 0 1 0 5.02A2.51 2.51 0 1 1 8 4.5z"></path></svg>
                    </div>
                    <div class="content">
                      <span class="text">
                        <a href="#" @click.prevent="moveMapTo(-1, item.id)" class="gray">
                          {{item.locAddr}}
                        </a>
                      </span>
                      <span class="tool">
                      <a href="#" @click.prevent="copyToClipBoard(item.locAddr)" class="green">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 13" class="addr-tool-ico" role="presentation"><path d="M9 8v.48l-1.98 1.58L6 11H3v1h4.61L10 9.81V3h-.97L9 8zm0-6h1a1 1 0 0 1 1 1v7.25L8 13H3a1 1 0 0 1-1-1v-1H1a1 1 0 0 1-1-1V1a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v1zm-7 8h3.5l.87-.7L8 7.81V1H1v9h1zm0-7h4v1H2V3zm0 2h4v1H2V5zm0 2h2v1H2V7z"></path></svg>
                        복사
                      </a>
                    </span>
                      <span class="tool">
                      <a href="#" @click.prevent="moveMapTo(index,item.id)" class="green">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 18" class="addr-tool-ico" role="presentation"><path d="M15 6.97a6.92 6.92 0 0 1-1.12 3.77l-5.51 7.08a.47.47 0 0 1-.74 0L2.1 10.71A6.93 6.93 0 0 1 1 6.97 7 7 0 0 1 8 0v.93V0a7 7 0 0 1 7 6.97zm-13 0c0 1.15.4 2.3.99 3.24L8 16.7l5.04-6.5A5.95 5.95 0 0 0 8 1C4.66 1 2 3.64 2 6.97zm6-1.54A1.58 1.58 0 0 0 8 8.6a1.57 1.57 0 0 0 0-3.16zm0-.93a2.51 2.51 0 0 1 0 5.02A2.51 2.51 0 1 1 8 4.5z"></path></svg>
                        지도
                      </a>
                    </span>
                      <span class="tool">
                      <a href="#" @click.prevent="openMap(index, 'to')" class="green">
                        <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                         viewBox="0 0 217.205 217.205" class="addr-tool-ico" style="enable-background:new 0 0 217.205 217.205;" xml:space="preserve"><g><path d="M215.008,103.299L113.906,2.196c-2.929-2.928-7.678-2.928-10.606,0L2.197,103.299c-2.929,2.93-2.929,7.678,0,10.607
                              l101.103,101.103c1.464,1.464,3.384,2.196,5.303,2.196c1.919,0,3.839-0.732,5.303-2.196l101.103-101.103
                              C217.938,110.976,217.938,106.228,215.008,103.299z M108.603,199.098l-90.496-90.496l90.496-90.496l90.496,90.496L108.603,199.098z
                              "/><path d="M121.998,81.07h-21.298c-11.633,0-21.098,9.465-21.098,21.099v39.406c0,4.143,3.358,7.5,7.5,7.5c4.142,0,7.5-3.357,7.5-7.5
                              v-39.406c0-3.363,2.735-6.099,6.098-6.099h21.298l-6.217,6.216c-2.929,2.929-2.929,7.678-0.001,10.606
                              c1.465,1.465,3.384,2.197,5.304,2.197c1.919,0,3.839-0.732,5.303-2.196l19.021-19.02c1.406-1.406,2.197-3.314,2.197-5.304
                              c0-1.989-0.79-3.897-2.197-5.304l-18.942-18.94c-2.93-2.928-7.678-2.929-10.607,0.001c-2.929,2.929-2.928,7.678,0.001,10.606
                              L121.998,81.07z"/></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g>
                        </svg>
                        길찾기
                      </a>
                    </span>
                    </div>
                  </div>
                  <div v-else>
                    <div class="label">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 18" class="addr-label-ico" role="presentation"><path d="M15 6.97a6.92 6.92 0 0 1-1.12 3.77l-5.51 7.08a.47.47 0 0 1-.74 0L2.1 10.71A6.93 6.93 0 0 1 1 6.97 7 7 0 0 1 8 0v.93V0a7 7 0 0 1 7 6.97zm-13 0c0 1.15.4 2.3.99 3.24L8 16.7l5.04-6.5A5.95 5.95 0 0 0 8 1C4.66 1 2 3.64 2 6.97zm6-1.54A1.58 1.58 0 0 0 8 8.6a1.57 1.57 0 0 0 0-3.16zm0-.93a2.51 2.51 0 0 1 0 5.02A2.51 2.51 0 1 1 8 4.5z"></path></svg>
                    </div>
                    <div class="content">
                      <span class="text">
                        <a class="gray">
                          {{item.locAddr}}
                        </a>
                      </span>
                      <span class="tool">
                        <a href="#" @click.prevent="copyToClipBoard(item.locAddr)" class="green">
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 13" class="addr-tool-ico" role="presentation"><path d="M9 8v.48l-1.98 1.58L6 11H3v1h4.61L10 9.81V3h-.97L9 8zm0-6h1a1 1 0 0 1 1 1v7.25L8 13H3a1 1 0 0 1-1-1v-1H1a1 1 0 0 1-1-1V1a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v1zm-7 8h3.5l.87-.7L8 7.81V1H1v9h1zm0-7h4v1H2V3zm0 2h4v1H2V5zm0 2h2v1H2V7z"></path></svg>
                          복사
                        </a>
                      </span>
                    </div>
                  </div>
                  <p v-if="item.subwayInfo !== undefined && item.subwayInfo !==''" v-html="subwayInfoStr(item.subwayInfo)"></p>
                </div>
                <div v-if="item.active && item.notice" class="segment">
                  <p class="comment">"{{item.notice}}"</p>
                </div>
                <div v-if="item.active && item.statistic.length > 0" class="segment">
                  <p v-if="screenWidth > 400">실제 검진 데이터로 분석한 이용자 선호도 분석</p>
                  <p v-else>실제 검진 데이터로 분석한 선호도 분석</p>
                  <div class="data">
                    <p v-for="(statisticItem) in item.statistic">
                      {{statisticItem.title}}
                      <img v-if="statisticItem.rate === 3" src="@/assets/images/graph3.png">
                      <img v-else src="@/assets/images/graph2.png">
                    </p>
                  </div>
                </div>
                <!--<div v-if="item.appointment && item.appointmentTime.times.length > 0" class="segment">
                  <template v-for="(item, tIdx) in item.appointmentTime.times">
                    {{ item.time }}&nbsp;
                  </template>
                </div>
                <div v-else-if="item.appointment && item.appointmentTime.times.length === 0" class="segment">
                  {{ item.appointmentTime.nextDate === null ? '일정이 없습니다.' : item.appointmentTime.nextDate}}
                </div>-->
                <div v-if="item.appointment && (item.appointmentTime.aTimes.length > 0 || item.appointmentTime.pTimes.length > 0)" class="reservation">
                  <p class="date">{{$moment(item.appointmentTime.date).format('YYYY.MM.DD(dd)')}}</p>
                  <div class="time">

                    <div v-if="item.appointmentTime.aTimes.length > 0"class="am">
                      <p>오전</p>
                      <div class="chk_box">
                        <div v-for="(time, tIdx) in item.appointmentTime.aTimes">
                          <input v-if="time.allow" type="radio" :id="'aTime' + index + '_' + tIdx" :value="time.time" v-model="item.appointmentTime.picked"/>
                          <input v-else type="radio" name="radio_chk" disabled/>
                          <label :for="'aTime' + index + '_' + tIdx">{{time.time}}</label>
                        </div>
                      </div>
                    </div>
                    <div v-if="item.appointmentTime.pTimes.length > 0"class="pm">
                      <p>오후</p>
                      <div class="chk_box">
                        <div v-for="(time, tIdx) in item.appointmentTime.pTimes">
                          <input v-if="time.allow" type="radio" :id="'pTime' + index + '_' + tIdx" :value="time.time" v-model="item.appointmentTime.picked"/>
                          <input v-else type="radio" name="radio_chk" disabled/>
                          <label :for="'pTime' + index + '_' + tIdx">{{time.time}}</label>
                        </div>
                      </div>
                    </div>
                   </div>
                </div>
                <div v-else-if="item.appointment && item.appointmentTime.nextDate !== null" class="reservation">
                  <div class="time">
                    <div class="am">
                      <p>다음 예약일</p>
                      <div class="chk_box2">
                        <div>
                          <input type="button"/>
                          <label @click="changeSelDate(item.appointmentTime.nextDate)">{{$moment(item.appointmentTime.nextDate).format('YYYY.MM.DD(dd)')}}</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div v-if="!isMobile" class="main_calendar">
                  <slick
                    :options="slickCalOptions"
                    :ref="'slickCal' + index"
                    class="btn_box"
                  >
                    <div
                      v-for="(calendar, calIdx) in item.calendars"
                      class="date_box"
                    >
                      <div v-for="(week, wIdx) in calendar.days" class="date">
                        <template v-for="(day, dIdx) in week">
                          <button v-if="day.day === 0" type="button" class="blank"></button>
                          <button v-else-if="day.diffDay <= 0" type="button" class="last_week"><p :style="day.radius">{{day.day}}</p><span v-if="day.diffDay === 0">오늘</span></button>
                          <button v-else-if="day.allow === true" :class="day.diffDay === 1 ? (item.selDate === day.date ? 'tomorrow active' : 'tomorrow'): (item.selDate === day.date ? 'active' : '')" type="button" @click="getAppointmentTime(index, day.date)"><p>{{day.day}}</p><span v-if="day.diffDay === 1">내일</span></button>
                          <button v-else type="button" :class="day.diffDay === 1 ? 'tomorrow' : ''" disabled><p>{{day.day}}</p><span v-if="day.diffDay === 1">내일</span></button>
                        </template>
                      </div>
                      <!--@click="appointmentDate(index, calIdx, wIdx, dIdx, day)"-->
                    </div>
                  </slick>
                </div>
                <div class="calendar">
                  <div v-if="!isMobile" class="calendar_desc">
                    <p><span></span>선택</p>
                    <p><span></span>불가</p>
                  </div>
                  <div class="link_box_v2">
                    <a v-if="item.appointment" href="#" class="reservation_btn" @click.prevent="openMakeAppointment(index)" ><p><img src="@/assets/images/reserve.svg" alt="" /></p>
                      검진 예약하기
                    </a>
                    <div class="bar"></div>
                    <a class="call_btn" :href="'tel:' + item.hmcTelNo"><p><img src="@/assets/images/call.svg" alt="" /></p>
                      전화문의
                    </a>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
        <div v-if="!isMobile" class="right_content">
          <div id="map" class="map">
            <vue-daum-map
              :appKey="mapAppKey"
              :center.sync="center"
              :level.sync="level"
              :mapTypeId="mapTypeId"
              :libraries="libraries"
              @load="onLoadMap"
              @click="onClickMap"
              style="width:100%;height:100%"
            />

            <button class="refresh" @click="resetBound()">처음 위치로</button>

            <div class="custom_zoomcontrol">
                <span @click="zoomIn()">
                  <img
                    class="zoom_in"
                    src="data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2218%22%20height%3D%2218%22%20viewBox%3D%220%200%2018%2018%22%3E%0A%20%20%3Cpolygon%20fill%3D%22%23666%22%20points%3D%2218%2C7%2011%2C7%2011%2C0%207%2C0%207%2C7%200%2C7%200%2C11%207%2C11%207%2C18%2011%2C18%2011%2C11%2018%2C11%22%2F%3E%0A%3C%2Fsvg%3E%0A"
                    alt="확대"
                  />
                </span>
              <span @click="zoomOut()">
                  <img
                    class="zoom_out"
                    src="data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2218%22%20height%3D%2218%22%20viewBox%3D%220%200%2018%2018%22%3E%0A%20%20%3Cpath%20fill%3D%22%23666%22%20d%3D%22M0%2C7h18v4H0V7z%22%2F%3E%0A%3C%2Fsvg%3E%0A"
                    alt="축소"
                  >
                </span>
            </div>

            <!-- 비활성시 -->
            <!-- <button class="refresh disabled">처음 위치로</button> -->
            <!-- //비활성시 -->

            <!--<a href="#0" class="marker"></a>-->
            <!--<a href="#0" class="marker marker1"></a>-->
            <!--<a href="#0" class="marker marker2"></a>-->
            <!--<div class="tooltip">강남베스트내과의원</div>-->
          </div>
        </div>
        <div v-else class="right_content">
          <div id="map" class="map">
            <vue-daum-map
              :appKey="mapAppKey"
              :center.sync="center"
              :level.sync="level"
              :mapTypeId="mapTypeId"
              :libraries="libraries"
              style="display: none;"
            />
          </div>
        </div>
      </div>
      <div class="bbs_text_type_top">
        <p>본 기본정보는 건강보험심사평가원의 정보를 기반으로 제공되며, 일부 추가 정보등록을 통해 상세정보가 제공 됩니다.</p>
      </div>
      <div v-if="!hlink" class="bbs_text_type_paging">
        <ul>
          <li class="ico prev">
            <a v-if="paging.currentPage === 1" href="#" class="none">
              &lt;
            </a>
            <a v-else href="#" @click.prevent="pageTo(paging.currentPage-1)">
              &lt;
            </a>
          </li>
          <li v-for="(page) in paging.pages" :class="page === paging.currentPage ? 'on' : ''">
            <a href="#" @click.prevent="pageTo(page)">{{page}}</a>
          </li>

          <li class="ico last">
            <a v-if="paging.currentPage === paging.totalPages" href="#" class="none">
              &gt;
            </a>
            <a v-else href="#" @click.prevent="pageTo(paging.currentPage+1)">
              &gt;
            </a>
          </li>
        </ul>
      </div>
    </div>
    <footer>
      <div class="inner">
        <h3 class="logo"><a href="http://www.fruits5.co.kr" target="_blank"><img src="@/assets/images/logo.png" alt="로고"></a></h3>
        <div>
          <p>(주)잇츠헬스</p>
          <div>
            <a href="http://www.fruits5.co.kr/policy/kcheckup_s_terms/" target="_blank">이용약관</a>
            <p>|</p>
            <a href="http://www.fruits5.co.kr/policy/kcheckup_s_privacy/" target="_blank">개인정보처리방침</a>
          </div>
        </div>
        <p>
          <span>서울시 마포구 백범로 31길 8, 201동 315호</span>
          <span><a href='tel:1644-4683'><span style="font-weight:bold">TEL</span> 1644-4683</a></span>
          <span><span>FAX</span> 02-6008-4683</span><br />
          <span><span>대표이사</span> 이영국</span>
          <span><span>사업자등록번호</span> 499-86-01106</span>
          <span><span>통신판매신고</span> 2019-서울서대문-0704</span>
        </p>
        <span class="copy">Copyrights © 2023 itshealth All rights reserved</span>
      </div>
    </footer>
    <div v-show="showBg" class="popup_bg"></div>
    <div v-show="showBg2" class="popup_bg_v2"></div>
    <div v-if="showCalendar" class="popup3 animated fadeIn">
      <Calendar
        :calendarHeads="calendarHeads"
        :today="date"
        :baseDate="selDate"
        @close="closeCalendar"
      />
    </div>
    <div v-if="showMakeAppointment" class="popup animated fadeIn">
      <MakeAppointment
        ref="refMakeAppointment"
        :calendarHeads="calendarHeads"
        :today="date"
        :baseDate="selDate"
        :checkup="checkups[selectedCheckupIdx]"
        :channel="ch"
        @close="closeMakeAppointment"
        @privacy="openPrivacy"
        @alert="openBtAlert"
      />
    </div>
    <div v-if="showViewAppointment" class="popup animated fadeIn">
      <ViewAppointment
        ref="viewAppointment"
        :calendarHeads="calendarHeads"
        :today="date"
        :appointment="appointmentInfo"
        @close="closeViewAppointment"
        @alert="openBtAlert"
        @cancel="cancelAppointmentOpen"
      />
    </div>
    <div v-if="showPrivacy" class="popup2" animated fadeIn>
      <div class="popup_contents">
        <div class="popup_title">
          <h5>개인 정보 처리 방침</h5>

          <button class="popup_close" @click="closePrivacy">x</button>
        </div>

        <div class="segment">
          <p>
            <b>개인정보 수집이용에 대한 동의</b><br /><br />
            귀하의 소중한 개인정보는 다음과 같은 정책에 따라 수집 및 이용됩니다. 해당 목적에 연관되는 개인정보만을 수집하며,<br />
            수집된 정보를 투명하고 안전하게 보호 관리할 것을 약속합니다. 이에 개인정보 수집 및 이용에 대한 동의를 구합니다.
            <br /><br />
            <b>○ 수집 및 이용목적</b><br /><br />
            건강검진 예약 및 안내.<br /><br />
            <b>○ 수집항목</b><br /><br />
            필수 : 성명, 성별, 생년월일, 휴대전화번호<br /><br />

            <b>○ 보유 및 이용기간</b><br /><br />
            관련 개인정보보호법 및 의료법 기준에 준함<br />
            정보주체는 개인정보의 수집 및 이용 동의를 거부할 권리가 있으나, 이 경우 예약 서비스가 제한될 수 있습니다.
          </p>
        </div>
      </div>
    </div>

    <div v-if="showFindAppointment" class="popup5">
      <div class="popup_contents">
        <div class="popup_title">
          <h5> {{findAppointmentNoneActive === false ? '예약내역 조회' : '알 림' }}</h5>

          <button class="popup_close" @click="closeFindAppointment">x</button>
          <!--<a href="./index.html" class="popup_close">x</a>-->
        </div>
        <div v-if="!findAppointmentNoneActive" class="segment">
          <div class="input_box">
            <p>예약번호<span class="red">*</span></p>
            <input type="text" ref="findNo" placeholder="예약번호를 입력해주세요." v-model="appointmentNo" />
          </div>
          <div class="input_box phone">
            <p>휴대전화번호 (-없이 입력)<span class="red">*</span></p>
            <div class="flex_box">
              <select class="select1" v-model="prefix_phone">
                <option value="010" selected>010</option>
              </select>
              <input type="text" ref="findPhone" placeholder="휴대폰번호를 입력해주세요." v-model="appointmentPhone" required />
              <p v-if="findAppointmentMsg" class="dec">{{ findAppointmentMsg }}</p>
              <button :disabled="!validatePhone" @click="requestFindAppointment()">예약확인</button>
            </div>
          </div>
        </div>
        <div v-else class="segment">
          <h5 class="cancel_title">
            {{appointmentInfo.hmcNm}}은
            <p>{{$moment(appointmentInfo.checkup.expirationDate).add(8, 'day').format('YYYY.MM.DD') }} 이후로 국가검진 모바일<br>예약 서비스를 제공하고 있지 않습니다.</p>
            <p>검진 예약 및 관련 문의는<br>☎ {{appointmentInfo.checkup.hmcTelNo}}<br>직접 연락 부탁드립니다.</p>
          </h5>
        </div>
      </div>
    </div>

    <div v-if="cancelMsg !== null" class="popup6">
      <div class="popup_contents">
        <div class="popup_title">
          <h5>예약 취소</h5>
          <button class="popup_close" @click="cancelAppointmentClose">x</button>
        </div>
        <div class="segment">
          <h5 class="cancel_title">
            {{appointmentInfo.checkup.hmcNm}}
            <p>{{cancelMsg}}</p>
          </h5>
          <p class="cancel">취소하시겠습니까?</p>
        </div>
        <div class="btn_box">
          <button class="popup_close" @click="cancelAppointmentClose()">아니요</button>
          <button class="apply_btn" @click="cancelAppointment()">예</button>
        </div>
      </div>
    </div>

    <div v-if="btAlertMsg !== null" class="popup4" animated fadeInUp>
      <div class="inner">
        <button class="popup_close" @click="btAlertOk">x</button>
        <p v-html="btAlertMsg.text"></p>
        <div class="btn_box">
          <button v-if="btAlertMsg.code === 3"class="apply_btn" @click="createAppointment">예약접수</button>
          <button v-else class="apply_btn" @click="btAlertOk">확인</button>
          <!--<button class="popup_close" @click="btAlertCancel">취소</button>-->
        </div>
      </div>
    </div>



  </div>
</template>

<script>
// @ is an alias to /src
import Calendar from '@/components/Calendar.vue'
import MakeAppointment from '@/components/MakeAppointment.vue'
import ViewAppointment from '@/components/ViewAppointment.vue'
import $ from 'jquery'
import Stickyfill from 'stickyfill'
import Slick from 'vue-slick'
import 'slick-carousel/slick/slick.css'
import VueDaumMap from 'vue-daum-map'
import { swiper, swiperSlide } from 'vue-awesome-swiper'
// import "swiper/css/swiper.css";
import 'swiper/dist/css/swiper.css'

var stickyfill = Stickyfill()
const publicIp = require('public-ip')
const MobileDetect = require('mobile-detect')


export default {
  name: 'Home',
  components: {
    VueDaumMap,
    Slick,
    swiper,
    swiperSlide,
    Calendar,
    MakeAppointment,
    ViewAppointment,
  },
  props: {
    shot: {
      type: Boolean,
      default: false,
    },
    avocado: {
      type: Boolean,
      default: false,
    },
    no: {
      type: String,
      default: '',
    },
    hmcNo: {
      type: String,
      default: '',
    },
    vno: {
      type: String,
      default: '',
    },
    lk: {
      type: Boolean,
      default: false,
    },
    ch: {
      type: String,
      default: 'web',
    }
  },
  data: () => ({

    boost : '',
    hlink : false,
    myLocationCls: 'widget-mylocation-button-icon widget-mylocation-button-normal',
    isMobile: false,
    screenWidth: 0,
    siDoCd: '11',
    siGunGuCds: [],
    siGunGuNm: '강남구',
    addrLevel: 2,
    addrs: [],
    selectedAddrIdx: -1,
    selectedCheckupIdx: -1,

    slickTopOptions: {
      // slide: '.bookMonth',
      speed: 300,
      loop: true,
      slidesToShow: 1,
      infinite: false,
      //prevArrow: '<button class="prev btn-prev" style="width:32px;"><img src="/images/arrow.png" alt="이전달"></button>',
      //nextArrow: '<button class="next btn-next" style="width:32px;"><img src="/images/arrow.png" alt="다음달"></button>',

      prevArrow: '<button class="btn-prev" onclick="topCalPrev()"></button>',
      nextArrow: '<button class="btn-next" onclick="topCalNext()"></button>'
      // prevArrow: $('#dd-nav-prev>img'),
      // nextArrow: $('#dd-nav-next>img')
    },

    slickCalOptions: {
      // slide: '.bookMonth',
      speed: 300,
      loop: true,
      slidesToShow: 1,
      draggable: false,
      infinite: false,
      prevArrow: $('#dd-nav-prev>img'),
      nextArrow: $('#dd-nav-next>img')
    },


    swiperCalHeadOption: {
      spaceBetween: 10,
      navigation: {
        prevEl: '.swiper-button-prev_2',
        nextEl: '.swiper-button-next_2'
      }
    },


    swiperMCalHeadOption: {
      spaceBetween: 0,
      navigation: {
        prevEl: '.swiper-button-prev_3',
        nextEl: '.swiper-button-next_3'
      }
    },

    currentPage: 1,
    pageSize: 15,
    defaultPageSize : 15,
    maxPages: 5,
    total: 0,
    paging: {},

    mapAppKey: '120d701b8fd37692af581c8dc9b01965', // 지도 API Key
    center: {
      lat: 33.450701,
      lng: 126.570667
    }, // 지도의 중심 좌표
    level: 3, // 지도의 레벨(확대, 축소 정도),
    mapTypeId: VueDaumMap.MapTypeId.NORMAL, // 맵 타입
    libraries: [
      'services'
    ], // 추가로 불러올 라이브러리
    map: null, // 지도 객체. 지도가 로드되면 할당됨
    mapBounds: null, // 결과 기준의 지도 영역, 검색 조회시 할당됨
    markerMap: {},
    selectedMarker: null,
    mapOverlay: null, // 지도 인포인도우
    checkups: [], // 병원 목록
    appointmentCheckups: [], // 예약 가능 병원 목록
    calendarHeads: [], // 상단 월간 캘린더 인디케이터
    mCalendarHeads: [], // 모바일 일간 캘린더 인디케이터
    mCalendarDates: [], // 모바일 일간 데이트
    mCalendarStartIdx: 0, //  모바일 일간 데이트 시작 인덱스
    appointmentNo: '', // 예약번호
    prefix_phone: '010',
    appointmentPhone: '', // 예약 휴대폰 번호
    date: null, // 현재시간 (대한민국)
    selDate: null, // 선택날자

    showMap: false,

    calendarMaxIdx: 4, // 예약 캘린더 최대 인덱스
    // calendarTitle: '', // 예약 캘린더 년월 (yyyy년 m월)

    showBg: false, // 기본 팝업 백그라운드
    showBg2: false, // 팝업의 팝업 백그라운드
    showCalendar: false,
    showMakeAppointment: false, // 신규 예약하기 팝업
    showViewAppointment: false, // 예약 내용 보기 팝업
    showPrivacy: false,
    showFindAppointment: false,
    findAppointmentMsg: '',
    findAppointmentNoneActive: false,
    appointmentInfo: null, // 조회된 예약 정보

    btAlertMsg: null,
    ctAlertMsg: null,
    cancelMsg: null,

    /*
    MARKER_WIDTH: 33, // 기본, 클릭 마커의 너비
    MARKER_HEIGHT: 36, // 기본, 클릭 마커의 높이
    OFFSET_X: 12, // 기본, 클릭 마커의 기준 X좌표
    OFFSET_Y: 36, // 기본, 클릭 마커의 기준 Y좌표
    OVER_MARKER_WIDTH: 40, // 오버 마커의 너비
    OVER_MARKER_HEIGHT: 42, // 오버 마커의 높이
    OVER_OFFSET_X: 13, // 오버 마커의 기준 X좌표
    OVER_OFFSET_Y: 42, // 오버 마커의 기준 Y좌표
    SPRITE_MARKER_URL: 'https://t1.daumcdn.net/localimg/localimages/07/mapapidoc/markers_sprites2.png', // 스프라이트 마커 이미지 URL
    SPRITE_WIDTH: 126, // 스프라이트 이미지 너비
    SPRITE_HEIGHT: 146, // 스프라이트 이미지 높이
    SPRITE_GAP: 10, // 스프라이트 이미지에서 마커간 간격
    */

    MARKER_WIDTH: 30, // 기본, 클릭 마커의 너비
    MARKER_HEIGHT: 39, // 기본, 클릭 마커의 높이
    OFFSET_X: 15, // 기본, 클릭 마커의 기준 X좌표
    OFFSET_Y: 39, // 기본, 클릭 마커의 기준 Y좌표
    OVER_MARKER_WIDTH: 44, // 오버 마커의 너비
    OVER_MARKER_HEIGHT: 54, // 오버 마커의 높이
    OVER_OFFSET_X: 17, // 오버 마커의 기준 X좌표
    OVER_OFFSET_Y: 54, // 오버 마커의 기준 Y좌표
    SPRITE_MARKER_URL: '/images/markers_sprites.png', // 스프라이트 마커 이미지 URL
    SPRITE_WIDTH: 126, // 스프라이트 이미지 너비
    SPRITE_HEIGHT: 146, // 스프라이트 이미지 높이
    SPRITE_GAP: 10, // 스프라이트 이미지에서 마커간 간격

    markerSize: null, // 기본, 클릭 마커의 크기
    markerOffset: null, // 기본, 클릭 마커의 기준좌표
    overMarkerSize: null, // 오버 마커의 크기
    overMarkerOffset: null, // 오버 마커의 기준 좌표
    spriteImageSize: null, // 스프라이트 이미지의 크기
    gapX: -1, // 스프라이트 이미지에서 마커로 사용할 이미지 X좌표 간격 값
    originY: -1, // 스프라이트 이미지에서 기본, 클릭 마커로 사용할 Y좌표 값
    overOriginY: -1, // 스프라이트 이미지에서 오버 마커로 사용할 Y좌표 값
    normalOrigin: null, // 스프라이트 이미지에서 기본 마커로 사용할 영역의 좌상단 좌표
    clickOrigin: null, // 스프라이트 이미지에서 마우스오버 마커로 사용할 영역의 좌상단 좌표
    overOrigin: null, // 스프라이트 이미지에서 클릭 마커로 사용할 영역의 좌상단 좌표
    shortAddrTable: {
      11: '서울',
      26: '부산',
      27: '대구',
      28: '인천',
      29: '광주',
      30: '대전',
      31: '울산',
      36: '세종',
      41: '경기',
      42: '강원',
      43: '충북',
      44: '충남',
      45: '전북',
      46: '전남',
      47: '경북',
      48: '경남',
      50: '제주'
    },
    /*
    titleTable: {
      localhost: '개발시',
      andong: '안동시',
      ansan: '안산시',
      anseong: '안성시',
      anyang: '안양시',
      asan: '아산시',
      boeun: '보은군',
      bonghwa: '봉화군',
      boseong: '보성군',
      brcn: '보령시',
      bsbukgu: '북구',
      bsdonggu: '동구',
      bsgangseo: '강서구',
      bsjunggu: '중구',
      bsnamgu: '남구',
      bsseogu: '서구',
      buan: '부안군',
      bucheon: '부천시',
      bukdaegu: '북구',
      bukgugwangju: '북구',
      bukguulsan: '북구',
      busanjin: '부산진구',
      buyeo: '부여군',
      changwon: '창원시',
      cheonan: '천안시',
      cheongdo: '청도군',
      cheongju: '청주시',
      cheongyang: '청양군',
      chilgok: '칠곡군',
      chuncheon: '춘천시',
      chungju: '충주시',
      cng: '창녕군',
      cs: '청송군',
      cwg: '철원군',
      daedeok: '대덕구',
      dalseodaegu: '달서구',
      dalseongdaegu: '달성군',
      damyang: '담양군',
      dangjin: '당진시',
      danyang: '단양군',
      ddc: '동두천시',
      ddm: '동대문구',
      dgs: '서구',
      dh: '동해시',
      djjunggu: '중구',
      dobong: '도봉구',
      dongdaegu: '동구',
      donggu: '동구',
      dongguulsan: '동구',
      dongjak: '동작구',
      dongnae: '동래구',
      ep: '은평구',
      eumseong: '음성군',
      gangbuk: '강북구',
      gangdong: '강동구',
      ganghwa: '강화군',
      gangjin: '강진군',
      gangnam: '강남',
      gangseo: '강서구',
      gbgs: '경산시',
      gbmg: '문경시',
      gc: '김천시',
      gccity: '과천시',
      geochang: '거창군',
      geoje: '거제시',
      geongeup: '정읍시',
      geumcheon: '금천구',
      geumjeong: '금정구',
      geumsan: '금산군',
      gijang: '기장군',
      gimhae: '김해시',
      gimje: '김제시',
      gimpo: '김포시',
      gjcity: '광주시',
      gjdongg: '동구',
      gm: '광명시',
      gn: '강릉시',
      gochang: '고창군',
      goesan: '괴산군',
      goheung: '고흥군',
      gokseong: '곡성군',
      gongju: '공주시',
      goryeong: '고령군',
      goseong: '고성군',
      goyang: '고양시',
      gp: '가평군',
      gumi: '구미시',
      gunpo: '군포시',
      gunsan: '군산시',
      gunwi: '군위군',
      guri: '구리시',
      guro: '구로구',
      gurye: '구례군',
      gwanak: '관악구',
      gwangjin: '광진구',
      gwangsan: '광산구',
      gwangyang: '광양시',
      gwgs: '고성군',
      gyeongju: '경주시',
      gyeryong: '계룡시',
      gyeyang: '계양구',
      hadong: '하동군',
      haenam: '해남군',
      haeundae: '해운대구',
      haman: '함안군',
      hampyeong: '함평군',
      hanam: '하남시',
      hc: '합천군',
      hongcheon: '홍천군',
      hongseong: '홍성군',
      hscity: '화성시',
      hsg: '횡성군',
      hwasun: '화순군',
      hygn: '함양군',
      icbp: '부평구',
      icdonggu: '동구',
      icheon: '이천시',
      icjg: '중구',
      ihc: '화천군',
      iksan: '익산시',
      imsil: '임실군',
      inje: '인제군',
      jangheung: '장흥군',
      jangseong: '장성군',
      jangsu: '장수군',
      jecheon: '제천시',
      jeongseon: '정선군',
      jeonju: '전주시',
      jinan: '진안군',
      jincheon: '진천군',
      jindo: '진도군',
      jinju: '진주시',
      jongno: '종로구',
      jp: '증평군',
      jungdaegu: '중구',
      junggu: '중구',
      jungguulsan: '중구',
      jungnang: '중랑구',
      mapo: '마포구',
      michuhol: '미추홀구',
      miryang: '밀양시',
      mokpo: '목포시',
      muan: '무안군',
      muju: '무주군',
      naju: '나주시',
      namdaegu: '남구',
      namdong: '남동구',
      namgugwangju: '남구',
      namhae: '남해군',
      namwon: '남원시',
      nonsan: '논산시',
      nowon: '노원구',
      nyj: '남양주시',
      oc: '옥천군',
      ongjin: '옹진군',
      osan: '오산시',
      paju: '파주시',
      pc: '평창군',
      pocheon: '포천시',
      pohang: '포항시',
      pyeongtaek: '평택시',
      sacheon: '사천시',
      saha: '사하구',
      samcheok: '삼척시',
      sancheong: '산청군',
      sangju: '상주시',
      sasang: '사상구',
      sb: '성북구',
      sd: '성동구',
      sdm: '서대문구',
      sejong: '세종시',
      seo: '서구',
      seocheon: '서천군',
      seocho: '서초구',
      seogu: '서구',
      seogugwangju: '서구',
      seongnam: '성남시',
      seosan: '서산시',
      shinan: '신안군',
      siheung: '시흥시',
      sj: '성주군',
      sokcho: '속초시',
      songpa: '송파구',
      sunchang: '순창군',
      suncheon: '순천시',
      suseong: '수성구',
      suwon: '수원시',
      suyeong: '수영구',
      taean: '태안군',
      taebaek: '태백시',
      tongyeong: '통영시',
      ui4u: '의정부시',
      uiryeong: '의령군',
      uiwang: '의왕시',
      uljin: '울진군',
      uljuulsan: '울주군',
      ulleung: '울릉군',
      ulsannamgu: '남구',
      usc: '의성군',
      wando: '완도군',
      wanju: '완주군',
      wonju: '원주시',
      yangcheon: '양천구',
      yanggu: '양구군',
      yangju: '양주시',
      yangsan: '양산시',
      yangyang: '양양군',
      yc: '영천시',
      ycg: '예천군',
      yd: '영덕군',
      yd21: '영동군',
      ydp: '영등포구',
      yeoju: '여주시',
      yeoncheon: '연천군',
      yeongam: '영암군',
      yeongdo: '영도구',
      yeonggwang: '영광군',
      yeongju: '영주시',
      yeonje: '연제구',
      yeonsu: '연수구',
      yeosu: '여수시',
      yesan: '예산군',
      yongin: '용인시',
      yongsan: '용산구',
      yp21: '양평군',
      yuseong: '유성구',
      yw: '영월군',
      yyg: '영양군'
    },
    */
    subwayTypeTable: {
      1: { lineName: '수도권 1호선', dispName: '1', color: '#263c96' },
      2: { lineName: '수도권 2호선', dispName: '2', color: '#3cb44a' },
      3: { lineName: '수도권 3호선', dispName: '3', color: '#ff7300' },
      4: { lineName: '수도권 4호선', dispName: '4', color: '#2c9dde' },
      5: { lineName: '수도권 5호선', dispName: '5', color: '#8836e0' },
      6: { lineName: '수도권 6호선', dispName: '6', color: '#b5500b' },
      7: { lineName: '수도권 7호선', dispName: '7', color: '#697215' },
      8: { lineName: '수도권 8호선', dispName: '8', color: '#e51e6e' },
      9: { lineName: '수도권 9호선', dispName: '9', color: '#d1a62c' },
      101: { lineName: '공항철도', dispName: '공항', color: '#73b7e4' },
      102: { lineName: '자기부상철도', dispName: '자기부상', color: '#ff9c5a' },
      104: { lineName: '경의중앙선', dispName: '경의중앙', color: '#7cc4a5' },
      107: { lineName: '에버라인', dispName: '에버라인', color: '#76c371' },
      108: { lineName: '경춘선', dispName: '경춘', color: '#08af7b' },
      109: { lineName: '신분당선', dispName: '신분당', color: '#a71e30' },
      110: { lineName: '의정부경전철', dispName: '의정부', color: '#ff9e27' },
      112: { lineName: '경강선', dispName: '경강', color: '#2674f2' },
      113: { lineName: '우이신설선', dispName: '우이신설', color: '#c6c100' },
      114: { lineName: '서해선', dispName: '서해', color: '#8bc53f' },
      115: { lineName: '김포골드라인', dispName: '김포골드', color: '#96710a' },
      116: { lineName: '수인분당선', dispName: '수인분당', color: '#fdc81d' },
      117: { lineName: '신림선', dispName: '신림', color: '#4e67a5' },
      21: { lineName: '인천 1호선', dispName: '인천1', color: '#6f99d0' },
      22: { lineName: '인천 2호선', dispName: '인천2', color: '#ffb850' },
      31: { lineName: '대전 1호선', dispName: '1', color: '#3cb44a' },
      41: { lineName: '대구 1호선', dispName: '1', color: '#f0602f' },
      42: { lineName: '대구 2호선', dispName: '2', color: '#3cb44a' },
      43: { lineName: '대구 3호선', dispName: '3', color: '#fec057' },
      51: { lineName: '광주 1호선', dispName: '1', color: '#3cb44a' },
      71: { lineName: '부산 1호선', dispName: '1', color: '#f0602f' },
      72: { lineName: '부산 2호선', dispName: '2', color: '#3cb44a' },
      73: { lineName: '부산 3호선', dispName: '3', color: '#d4a556' },
      74: { lineName: '부산 4호선', dispName: '4', color: '#426fb5' },
      78: { lineName: '동해선', dispName: '동해', color: '#a3c3e2' },
      79: { lineName: '부산-김해경전철', dispName: '부산김해', color: '#80499c' }
    },
    searchOptions: {
      items: [
        {
          orgText: '일반검진',
          text: '일반검진',
          selected: false,
          keys: ['hs.grenChrgTypeCd'],
          value: '1',
          showChild: false,
          child: null
        },
        {
          orgText: '암검진',
          text: '암검진',
          selected: false,
          keys: ['hs.stmcaExmdChrgTypeCd', 'hs.ccExmdChrgTypeCd', 'hs.lvcaExmdChrgTypeCd', 'hs.lncaExmdChrgTypeCd'],
          value: '1',
          showChild: false,
          child: null
        },
        {
          orgText: '여성검진',
          text: '여성검진',
          selected: false,
          keys: ['hs.bcExmdChrgTypeCd', 'hs.cvxcaExmdChrgTypeCd'],
          value: '1',
          showChild: false,
          subSettings: [],
          child: [
            {
              text: '유방암',
              selected: false,
              keys: ['hs.bcExmdChrgTypeCd'],
              value: '1'
            },
            {
              text: '자궁경부암',
              selected: false,
              keys: ['hs.cvxcaExmdChrgTypeCd'],
              value: '1'
            }
          ]
        }
      ]
    }
  }),

  computed : {
    validatePhone() {
      if(this.appointmentPhone === '') return false;

      var temp = this.prefix_phone + this.appointmentPhone.replace(new RegExp('-', 'g'), '');
      //temp = temp.replace(new RegExp('-', 'g'), '');
      // 총 핸드폰 자리수는 11글자이거나, 10자여야 합니다.
      if(temp.length==11 || temp.length==10) {
        var regExp = /^(01[016789]{1}|02|0[3-9]{1}[0-9]{1})([0-9]{3,4})([0-9]{4})$/;
        var re = new RegExp(regExp)
        return (re.test(temp))
      }else return false

    },
  },

  created: function () {

    const md = new MobileDetect(window.navigator.userAgent)

    if (md.mobile()) this.isMobile = true

    // 한국 시간으로 고정 설정
    const curr = new Date()
    const utc = curr.getTime() + (curr.getTimezoneOffset() * 60 * 1000)
    const KR_TIME_DIFF = 9 * 60 * 60 * 1000;

    this.date = new Date(utc + KR_TIME_DIFF)

    // this.date = new Date('2022/08/31 10:00:01');

    this.selDate = this.$moment(this.date).add(1, 'day').format('YYYYMMDD')

    this.hlink = this.lk

    this.loadHeadCalendar()
  },

  mounted: function () {
    const self = this

    if (this.isMobile) this.initAddr(window.location.hostname.split('.')[0])


    $('.sel_text').each(function () {
      $(this).click(function () {
        if (self.addrLevel === 2 && self.addrs.length > 0) $('.link_sel').slideToggle()
      })
    })

    $('.input_chk').change(function () {
      $('.input_chk:checked').length !== 0 ? $('.select_pop button').attr('disabled', false) : $('.select_pop button').attr('disabled', true)
    })

    if (this.shot === false) {
      var map = document.getElementById('map')
      stickyfill.add(map)

      var stickyTop = document.getElementById('sticky_top')
      stickyfill.add(stickyTop)
    }

    //console.log(this.no)

    if (this.no !== '') {

      if(isNaN(this.no)) this.openFindAppointment('')
      else this.openFindAppointment(this.no)
    }

    /*
    $(".list_wrap > .left_content .top .m_calendar_head .swiper-slide > p").click(function () {
      this.showCalendar = true
      //$(".popup3").fadeIn(300);
      //$(".popup_bg").fadeIn(300);
      $("body").css({ overflow: "hidden" });
      $("html").css({ overflow: "hidden" });
    });

    $(".popup3 .inner .popup_title .popup_close").click(function () {
      this.showCalendar = false
      //$(".popup3").fadeOut(300);
      //$(".popup_bg").fadeOut(300);
    });


    $(".popup_close").click(function () {
      $("body").css({ overflow: "" });
      $("html").css({ overflow: "" });
    });
    */



    this.maxPages = window.innerWidth < 980 ? 5 : 10
    this.screenWidth = window.innerWidth


    window.addEventListener('resize', this.handleResize)
    // this.onResize()
    // this.addrSerch(this.siDoCd)
  },
  beforeDestroy () {
    // console.log("beforeDestroy...");
    if (typeof window === 'undefined') return

    window.removeEventListener('resize', this.handleResize)
  },
  methods: {

    createAppointment() {
      this.showBg2 = false
      this.btAlertMsg = null
      this.$refs.refMakeAppointment.createAppintment()
    },
    openCalendar() {
      $("body").css({ overflow: "hidden" });
      $("html").css({ overflow: "hidden" });
      this.showCalendar = true
      // console.log(this.showCalendar)
    },

    closeCalendar(date) {
      $("body").css({ overflow: "" });
      $("html").css({ overflow: "" });
      this.showCalendar = false
      if(this.selDate !== date) this.changeSelDate(date)

    },


    openMakeAppointment(checkupIdx) {
      // console.log('openAppointment :' + checkupIdx)
      $("body").css({ overflow: "hidden" });
      $("html").css({ overflow: "hidden" });
      this.selectedCheckupIdx = checkupIdx
      this.showBg = true
      this.showMakeAppointment = true
      // console.log(this.showCalendar)
    },

    closeMakeAppointment() {
      $("body").css({ overflow: "" });
      $("html").css({ overflow: "" });
      this.showBg = false
      this.showMakeAppointment = false
    },

    openFindAppointment(no) {

      this.appointmentNo = no

      $("body").css({ overflow: "hidden" });
      $("html").css({ overflow: "hidden" });
      this.showBg = true
      this.findAppointmentNoneActive = false
      this.showFindAppointment = true

      if(this.appointmentNo === '') this.$nextTick(() => this.$refs.findNo.focus())
      else this.$nextTick(() => this.$refs.findPhone.focus())

    },

    closeFindAppointment() {
      $("body").css({ overflow: "" });
      $("html").css({ overflow: "" });
      this.showBg = false
      this.showFindAppointment = false
      this.appointmentInfo = null
    },

    openViewAppointment() {
      $("body").css({ overflow: "hidden" });
      $("html").css({ overflow: "hidden" });
      this.showBg = true
      this.showFindAppointment = false
      this.showViewAppointment = true
    },

    closeViewAppointment() {
      $("body").css({ overflow: "" });
      $("html").css({ overflow: "" });
      this.showBg = false
      this.showViewAppointment = false
    },

    openPrivacy() {
      this.showBg2 = true
      this.showPrivacy = true
    },
    closePrivacy() {
      this.showBg2 = false
      this.showPrivacy = false
    },

    openBtAlert(msg) {
      this.showBg2 = true
      this.btAlertMsg = msg
    },

    btAlertOk() {
      this.showBg2 = false
      this.btAlertMsg = null
    },

    btAlertCancel() {
      this.showBg2 = false
      this.ctAlertMsg = null

    },

    cancelAppointmentOpen(msg) {
      this.showBg2 = true
      this.cancelMsg = msg
    },

    cancelAppointmentClose() {
      this.showBg2 = false
      this.cancelMsg = null
    },

    cancelAppointment() {
      this.showBg2 = false
      this.cancelMsg = null
      //console.log('cancelAppointment')
      this.$refs.viewAppointment.cancelAppointment()
    },


    padLeft (n, width, z) {
      z = z || '0'
      n = n + ''
      return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n
    },
    handleResize (event) {
      this.screenWidth = window.innerWidth
      const maxPage = window.innerWidth < 980 ? 5 : 10

      if (this.maxPage !== maxPage && this.total > 0) {
        this.$nextTick(function () {
          this.maxPages = maxPage
          this.paging = this.paginate(this.total, this.paging.currentPage, this.pageSize, this.maxPages)
        })
      }
    },
    setAddrDefault () {
      this.siDoCd = '11'
      this.siGunGuCds = ['680']
      this.siGunGuNm = '강남구'
    },
    setAddrSejong () {
      this.siDoCd = '36'
      this.siGunGuCds = ['110']
      this.siGunGuNm = '세종시'
    },
    mylocation () {
      if (navigator.geolocation) {
        /**
         * navigator.geolocation 은 Chrome 50 버젼 이후로 HTTP 환경에서 사용이 Deprecate 되어 HTTPS 환경에서만 사용 가능 합니다.
         * http://localhost 에서는 사용이 가능하며, 테스트 목적으로, Chrome 의 바로가기를 만들어서 아래와 같이 설정하면 접속은 가능합니다.
         * chrome.exe --unsafely-treat-insecure-origin-as-secure="http://example.com"
         */
        this.myLocationCls = 'widget-mylocation-button-icon widget-mylocation-button-finding'
        navigator.geolocation.getCurrentPosition(this.onSuccessGeolocation, this.onErrorGeolocation)
      } else {
      }
    },

    onSuccessGeolocation (position) {
      var geocoder = new kakao.maps.services.Geocoder()

      // POINT(127.1198095270886 37.38527504238805) // 분당
      // POINT(127.287016635987 36.5927075455207) // 세종시
      // geocoder.coord2RegionCode(36.5927075455207, 127.287016635987, this.onRegionCode);

      geocoder.coord2RegionCode(position.coords.longitude, position.coords.latitude, this.onRegionCode)
    },
    onErrorGeolocation () {
      this.myLocationCls = 'widget-mylocation-button-icon widget-mylocation-button-normal'
    },
    onRegionCode (result, status) {
      const self = this

      this.hlink = false

      if (status === kakao.maps.services.Status.OK) {
        this.myLocationCls = 'widget-mylocation-button-icon widget-mylocation-button-blue'

        // console.log('지역 명칭 : ' + result[0].address_name);
        // console.log('행정구역 코드 : ' + result[0].code);

        const sidoCd = result[0].code.substring(0, 2)
        const siGunGuNm = result[0].address_name.split(' ')[1]

        if (sidoCd === '36') { // 세종시 예외처리
          self.setAddrSejong()
          self.addrSearch(self.siDoCd)
          self.hospitalSearch(self.siDoCd, self.siGunGuCds, 1)
        } else {
          const queryObj = {
            siDoCd: sidoCd,
            siGunGuNm: { $text: siGunGuNm + '^' }
          }

          this.$http.get(this.$apiUrl + 'base/addrs?order=siGunGuNm&where=' + encodeURIComponent(JSON.stringify(queryObj)))
            .then(response => {
              const data = response.data.results
              //console.log(data)

              if (data.length > 0) {
                self.siGunGuCds = []
                for (let i = 0; i < data.length; i++) {
                  if (i === 0) {
                    self.siDoCd = data[i].siDoCd
                    self.siGunGuNm = data[i].siGunGuNm.split(' ')[0]
                  }
                  self.siGunGuCds.push(data[i].siGunGuCd)
                }
              } else self.setAddrDefault()

              self.addrSearch(self.siDoCd)
              self.hospitalSearch(self.siDoCd, self.siGunGuCds, 1)
            })
            .catch(error => {
              console.log(error)
              self.setAddrDefault()
              self.addrSearch(self.siDoCd)
              self.hospitalSearch(self.siDoCd, self.siGunGuCds, 1)
            })
        }
      } else {
        self.myLocationCls = 'widget-mylocation-button-icon widget-mylocation-button-normal'
      }
    },
    async initAddr (keyword) {

      if (!isNaN(keyword.substr(keyword.length - 1)) && isNaN(keyword.substr(keyword.length - 2))) keyword = keyword.substr(0,keyword.length-1)

      // console.log('init addr - ' + keyword)
      const self = this

      const queryObj = {
        keywordNm: keyword
      }

      if(self.hmcNo !== '' || self.vno !== '') { // checkup_id 가 있는 경우 해당지역 노출 처리
        //console.log(self.hmcNo)
        let reqParam = (self.vno !== '') ? self.vno : self.hmcNo
        this.$http.get(this.$apiUrl + 'base/location/' + reqParam)
          .then(response => {
            const data = response.data.results
            //console.log(data)

            if (data.length > 0) {
              for (let i = 0; i < data.length; i++) {
                if (i === 0) {
                  self.siDoCd = data[i].siDoCd
                  self.siGunGuNm = data[i].siGunGuNm.split(' ')[0]
                }
                self.siGunGuCds.push(data[i].siGunGuCd)
              }
            } else self.setAddrDefault()

            self.boost = (self.vno !== '') ? response.data.prev_page_url : self.hmcNo

            self.addrSearch(self.siDoCd)
            self.hospitalSearch(self.siDoCd, self.siGunGuCds, 1)
          })
          .catch(error => {
            console.log(error)
            self.setAddrDefault()
            self.addrSearch(self.siDoCd)
            self.hospitalSearch(self.siDoCd, self.siGunGuCds, 1)
          })
      } else if (keyword === 'avocado') {
        self.avocado = true
        self.setAddrDefault()
        self.addrSearch(self.siDoCd)
        self.hospitalSearch(self.siDoCd, self.siGunGuCds, 1)

      } else if (keyword === 'www' || keyword === 'k-checkup' || keyword === 'localhost') {

        if (self.isMobile === false) { // 모바일이 아닌경우 아이피 타게팅 처리
          const clientIp = await publicIp.v4()
          this.$http.get(this.$apiUrl + 'base/location?ip=' + clientIp)
            .then(response => {
              const data = response.data.results
              // console.log(data)

              if (data.length > 0) {
                for (let i = 0; i < data.length; i++) {
                  if (i === 0) {
                    self.siDoCd = data[i].siDoCd
                    self.siGunGuNm = data[i].siGunGuNm.split(' ')[0]
                  }
                  self.siGunGuCds.push(data[i].siGunGuCd)
                }
              } else self.setAddrDefault()

              self.addrSearch(self.siDoCd)
              self.hospitalSearch(self.siDoCd, self.siGunGuCds, 1)
            })
            .catch(error => {
              console.log(error)
              self.setAddrDefault()
              self.addrSearch(self.siDoCd)
              self.hospitalSearch(self.siDoCd, self.siGunGuCds, 1)
            })
        } else {
          self.setAddrDefault()
          self.addrSearch(self.siDoCd)
          self.hospitalSearch(self.siDoCd, self.siGunGuCds, 1)
        }
      } else {
        this.$http.get(this.$apiUrl + 'base/addrs?order=siGunGuNm&where=' + encodeURIComponent(JSON.stringify(queryObj)))
          .then(response => {
            const data = response.data.results
            // console.log(data)

            if (data.length > 0) {
              for (let i = 0; i < data.length; i++) {
                if (i === 0) {
                  self.siDoCd = data[i].siDoCd
                  self.siGunGuNm = data[i].siGunGuNm.split(' ')[0]
                }
                self.siGunGuCds.push(data[i].siGunGuCd)
              }
            } else {
              self.siDoCd = '11'
              self.siGunGuCds.push('680')
            }
            self.addrSearch(self.siDoCd)
            self.hospitalSearch(self.siDoCd, self.siGunGuCds, 1)
          })
          .catch(error => {
            console.log(error)
          })
      }
    },
    addrSearch (siDoCd) {
      const self = this
      const equals = (a, b) => JSON.stringify(a) === JSON.stringify(b)

      self.addrs = []

      if (siDoCd !== '') { // 시군구 검색인 경우
        const queryObj = {
          siDoCd: siDoCd,
          siGunGuNm: { $exist: true }
        }

        this.$http.get(this.$apiUrl + 'base/addrs?order=siGunGuNm&where=' + encodeURIComponent(JSON.stringify(queryObj)))
          .then(response => {
            const data = response.data.results
            // console.log(data)

            let text = ''
            let values = []

            for (let i = 0; i < data.length; i++) {
              if (i === 0) {
                text = data[i].siGunGuNm.split(' ')[0]
                values.push(data[i].siGunGuCd)
              } else {
                if (text === data[i].siGunGuNm.split(' ')[0]) {
                  values.push(data[i].siGunGuCd)
                } else {
                  self.addrs.push({
                    text: text,
                    value: values
                  })

                  if (equals(self.siGunGuCds, values)) self.selectedAddrIdx = self.addrs.length - 1

                  text = data[i].siGunGuNm.split(' ')[0]
                  values = []
                  values.push(data[i].siGunGuCd)
                }
              }
            }
            if (data.length > 0) {
              self.addrs.push({
                text: text,
                value: values
              })

              if (equals(self.siGunGuCds, values)) self.selectedAddrIdx = self.addrs.length - 1
            }
          })
          .catch(error => {
            console.log(error)
          })
      } else {
        const queryObj = {
          siGunGuNm: { $exist: false }
        }

        this.$http.get(this.$apiUrl + 'base/addrs?where=' + encodeURIComponent(JSON.stringify(queryObj)))
          .then(response => {
            const data = response.data.results
            // console.log(data)

            for (let i = 0; i < data.length; i++) {
              self.addrs.push({
                text: data[i].siDoNm,
                siDoCd: data[i].siDoCd,
                siGunGuNm: data[i].keywordNm.split(':')[0],
                value: data[i].keywordNm.split(':')[1].split(',')
              })
            }
          })
          .catch(error => {
            console.log(error)
          })
      }
    },
    selectAddr (idx) {
      // console.log('addrLevel:' + this.addrLevel + ', idx:' + idx, ', sido:' + this.addrs[0].siDoCd)
      if (idx !== -1) {
        if (this.addrs[0].siDoCd === undefined) { // 시군구 선택인 경우
          this.addrLevel = 2
          this.selectedAddrIdx = idx
          this.siGunGuCds = this.addrs[idx].value
          this.siGunGuNm = this.addrs[idx].text
          this.hlink = false
          this.hospitalSearch(this.siDoCd, this.siGunGuCds, 1)
        } else {
          this.addrLevel = 2
          this.siDoCd = this.addrs[idx].siDoCd
          this.addrSearch(this.siDoCd)
        }
      } else { // 시도 레벨로 이동
        this.selectedAddrIdx = -1
        this.addrLevel = 1
        this.addrSearch('')
      }
    },

    calHeadSlideChange () {

      var currentSlide = this.$refs['calHeadSlide'].swiper.previousIndex
      var nextSlide = this.$refs['calHeadSlide'].swiper.realIndex

      // console.log(currentSlide + '-->' + nextSlide)

      if(this.checkups.length > 0) {
        if(this.checkups[0].calendars[nextSlide].days == null) this.loadAppointmentcalendars(nextSlide)

        for (let i = 0; i < this.checkups.length; i++) {
          this.$refs['slickCal' + i][0].goTo(nextSlide)
        }

      }
    },

    mCalHeadSlideChange () {

      // console.log('mCalHeadSlideChange : ' + this.$refs['mCalHeadSlide'].swiper.realIndex + ", " + this.selDate +"<>" + this.mCalendarHeads[this.$refs['mCalHeadSlide'].swiper.realIndex].date)

      this.selDate = this.mCalendarHeads[this.$refs['mCalHeadSlide'].swiper.realIndex].date
      this.loadAppointmentTime(this.mCalendarHeads[this.$refs['mCalHeadSlide'].swiper.realIndex].date)



      if (this.mCalendarStartIdx !== 0 && this.$refs['mCalHeadSlide'].swiper.realIndex === 0 && (this.mCalendarStartIdx) - 8 >= 0) {

        this.mCalendarStartIdx = this.mCalendarStartIdx - 8

        // console.log('StartIdx : ' + this.mCalendarStartIdx )


        this.mCalendarHeads = this.mCalendarDates.slice(this.mCalendarStartIdx, this.mCalendarStartIdx+10)

        this.$refs['mCalHeadSlide'].swiper.slideTo(8, 0, false)

        // console.log(this.mCalendarHeads)



      } else if (this.$refs['mCalHeadSlide'].swiper.realIndex === 9 && (this.mCalendarStartIdx) + 8 <= 90) {


        this.mCalendarStartIdx = this.mCalendarStartIdx + 8

        // console.log('StartIdx : ' + this.mCalendarStartIdx )


        this.mCalendarHeads = this.mCalendarDates.slice(this.mCalendarStartIdx, this.mCalendarStartIdx+10)

        this.$refs['mCalHeadSlide'].swiper.slideTo(1, 0, false)

        // console.log(this.mCalendarHeads)

      }
    },

    changeSelDate(date) {

      var idx = this.$moment(date, 'YYYYMMDD').diff(this.$moment(this.date, 'YYYYMMDD'),'day')

      //console.log('seldate : ' + date)

      this.selDate = date
      // this.$refs['mCalHeadSlide'].swiper.slideTo(idx)

      this.mCalendarStartIdx = Math.floor((idx-1) / 8) * 8

      // console.log('StartIdx : ' + this.mCalendarStartIdx  + ', slide : '  + (((idx-1) % 8) + 1) )


      this.mCalendarHeads = this.mCalendarDates.slice(this.mCalendarStartIdx, this.mCalendarStartIdx+10)

      this.$refs['mCalHeadSlide'].swiper.slideTo( ((idx-1) % 8) + 1 , 0, false)

      // console.log(this.mCalendarHeads)

    },

    resetCalendarSlide() {

      // var idx = this.$moment(this.selDate, 'YYYYMMDD').diff(this.$moment(this.date, 'YYYYMMDD'),'day')
      // console.log('resetCalendarSlide')

      this.$refs['calHeadSlide'].swiper.slideTo(0)

      for (let i = 0; i < this.checkups.length; i++) {
        this.$refs['slickCal' + i][0].reSlick();
      }

    },
    onClickMap () {
      if (this.mapOverlay != null) {
        this.mapOverlay.setMap(null)
        this.mapOverlay = null
      }
      if (this.selectedMarker != null) {
        this.selectedMarker.setImage(this.selectedMarker.normalImage)
        this.selectedMarker = null
      }
    },
    onLoadMap (map) {
      // console.log('onLoadMap')
      this.map = map
      this.map.setZoomable(false)

      // 일반 지도와 스카이뷰로 지도 타입을 전환할 수 있는 지도타입 컨트롤을 생성합니다
      // var mapTypeControl = new kakao.maps.MapTypeControl()
      // map.addControl(mapTypeControl, kakao.maps.ControlPosition.TOPLEFT)

      this.markerSize = new kakao.maps.Size(this.MARKER_WIDTH, this.MARKER_HEIGHT) // 기본, 클릭 마커의 크기
      this.markerOffset = new kakao.maps.Point(this.OFFSET_X, this.OFFSET_Y) // 기본, 클릭 마커의 기준좌표
      this.overMarkerSize = new kakao.maps.Size(this.OVER_MARKER_WIDTH, this.OVER_MARKER_HEIGHT) // 오버 마커의 크기
      this.overMarkerOffset = new kakao.maps.Point(this.OVER_OFFSET_X, this.OVER_OFFSET_Y) // 오버 마커의 기준 좌표
      this.spriteImageSize = new kakao.maps.Size(this.SPRITE_WIDTH, this.SPRITE_HEIGHT) // 스프라이트 이미지의 크기

      this.gapX = (this.MARKER_WIDTH + this.SPRITE_GAP) // 스프라이트 이미지에서 마커로 사용할 이미지 X좌표 간격 값
      this.originY = (this.MARKER_HEIGHT + this.SPRITE_GAP) * 0 // 스프라이트 이미지에서 기본, 클릭 마커로 사용할 Y좌표 값
      this.overOriginY = (this.OVER_MARKER_HEIGHT + this.SPRITE_GAP) * 0 // 스프라이트 이미지에서 오버 마커로 사용할 Y좌표 값
      this.normalOrigin = new kakao.maps.Point(0, this.originY) // 스프라이트 이미지에서 기본 마커로 사용할 영역의 좌상단 좌표
      this.clickOrigin = new kakao.maps.Point(this.gapX, this.originY) // 스프라이트 이미지에서 마우스오버 마커로 사용할 영역의 좌상단 좌표
      this.overOrigin = new kakao.maps.Point(this.gapX * 2, this.overOriginY) // 스프라이트 이미지에서 클릭 마커로 사용할 영역의 좌상단 좌표

      this.initAddr(window.location.hostname.split('.')[0])
    },

    paginate (totalItems, currentPage, pageSize, maxPages) {
      if (totalItems === 0) return {}
      // calculate total pages
      const totalPages = Math.ceil(totalItems / pageSize)

      // ensure current page isn't out of range
      if (currentPage < 1) {
        currentPage = 1
      } else if (currentPage > totalPages) {
        currentPage = totalPages
      }

      let startPage, endPage
      if (totalPages <= maxPages) {
        // total pages less than max so show all pages
        startPage = 1
        endPage = totalPages
      } else {
        // total pages more than max so calculate start and end pages
        const maxPagesBeforeCurrentPage = Math.floor(maxPages / 2)
        const maxPagesAfterCurrentPage = Math.ceil(maxPages / 2) - 1
        if (currentPage <= maxPagesBeforeCurrentPage) {
          // current page near the start
          startPage = 1
          endPage = maxPages
        } else if (currentPage + maxPagesAfterCurrentPage >= totalPages) {
          // current page near the end
          startPage = totalPages - maxPages + 1
          endPage = totalPages
        } else {
          // current page somewhere in the middle
          startPage = currentPage - maxPagesBeforeCurrentPage
          endPage = currentPage + maxPagesAfterCurrentPage
        }
      }

      // calculate start and end item indexes
      const startIndex = (currentPage - 1) * pageSize
      const endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1)

      // create an array of pages to ng-repeat in the pager control
      const pages = Array.from(Array((endPage + 1) - startPage).keys()).map(i => startPage + i)

      // return object with all pager properties required by the view
      return {
        totalItems: totalItems,
        currentPage: currentPage,
        pageSize: pageSize,
        totalPages: totalPages,
        startPage: startPage,
        endPage: endPage,
        startIndex: startIndex,
        endIndex: endIndex,
        pages: pages
      }
    },
    pageTo (page) {
      window.scrollTo(0, 0)
      this.$nextTick(function () {
        this.hospitalSearch(this.siDoCd, this.siGunGuCds, page)
      })
    },
    loadAppointmentcalendars(calIdx) {
      const self = this

      let year = this.checkups[0].calendars[calIdx].year
      let month = this.checkups[0].calendars[calIdx].month
      let calDate = new Date(year, month -1, 1)

      if(this.appointmentCheckups.length > 0) {
        this.$http.post(this.$apiUrl + 'appointment/schedules/' + year + '/' + ('0' + month).slice(-2), this.appointmentCheckups)
          .then(response => {
            const data = response.data

            let idx = 0

            for (let i = 0; i < self.checkups.length; i++) {
              if (self.checkups[i].appointment) {
                if(self.checkups[i].hospital_id = data[idx].hospital_id) {
                  self.checkups[i].calendars[calIdx].days = self.buildCalendar(data[idx], calDate)
                  // self.checkups[i].calendars[calIdx].days = b
                  idx++
                } else {
                  self.checkups[i].calendars[calIdx].days = self.dummyCalendar(calDate)
                }
              } else {
                self.checkups[i].calendars[calIdx].days = self.dummyCalendar(calDate)
              }
            }
          })
          .catch(error => {
            console.log(error)
          })
      } else {
        for (let i = 0; i < self.checkups.length; i++) {
          self.checkups[i].calendars[calIdx].days = self.dummyCalendar(calDate)
        }
      }

    },

    loadAppointmentTime(date) {
      const self = this

      if(this.appointmentCheckups.length > 0) {
        this.$http.post(this.$apiUrl + 'appointment/schedules/' + date.substr(0, 4) + '/' + date.substr(4, 2) + '/' + date.substr(6, 2), this.appointmentCheckups)
          .then(response => {
            const data = response.data

            // console.log(data)

            let idx = 0

            for (let i = 0; i < self.checkups.length; i++) {
              if (self.checkups[i].appointment) {
                if(self.checkups[i].hospital_id = data[idx].hospital_id) {
                  self.checkups[i].appointmentTime = self.appointmentTimeTables(data[idx])

                  if(data[idx].times.length > 0) self.checkups[i].selDate = data[idx].date
                  else self.checkups[i].selDate = ''

                  idx++
                }
              }
            }
          })
          .catch(error => {
            console.log(error)
          })
      }

    },

    hospitalSearch (sidoCd, siGunGuCds, page) {
      const self = this
      const queryObj = {}

      queryObj['hs.siDoCd'] = sidoCd

      if (siGunGuCds.length === 1) {
        queryObj['hs.siGunGuCd'] = siGunGuCds[0]
      } else {
        const filters = []

        for (let i = 0; i < siGunGuCds.length; i++) {
          filters.push({ 'hs.siGunGuCd': siGunGuCds[i] })
        }

        queryObj['$or'] = filters
      }

      // 아보카도 병원만 노출
      if (this.avocado) {
        queryObj['h.notice'] = { '$exist': true }
      }
      // 필터 설정
      for (let i = 0; i < this.searchOptions.items.length; i++) {
        if (this.searchOptions.items[i].child !== null) { // 서브 필터가 있는 경우 서브 필터 정보 체크
          const filters = []

          for (let j = 0; j < this.searchOptions.items[i].child.length; j++) {
            if (this.searchOptions.items[i].child[j].selected === true) {
              if (this.searchOptions.items[i].child[j].keys.length > 1) {
                for (let k = 0; k < this.searchOptions.items[i].child[j].keys.length; k++) {
                  filters.push({ [this.searchOptions.items[i].child[j].keys[k]]: '1' })
                }
              } else if (this.searchOptions.items[i].child[j].keys.length === 1) {
                queryObj[this.searchOptions.items[i].child[j].keys[0]] = '1'
              }
            }
          }

          if (filters.length > 0) queryObj['$zr'] = filters
        } else {
          const filters = []

          if (this.searchOptions.items[i].selected === true) {
            if (this.searchOptions.items[i].keys.length > 1) {
              for (let j = 0; j < this.searchOptions.items[i].keys.length; j++) {
                filters.push({ [this.searchOptions.items[i].keys[j]]: '1' })
              }
            } else if (this.searchOptions.items[i].keys.length === 1) {
              queryObj[this.searchOptions.items[i].keys[0]] = '1'
            }
          }

          if (filters.length > 0) queryObj['$zr'] = filters
        }
      }

      var all = false

      if(this.hlink) {
        this.pageSize = 1
        all = true
      }
      else this.pageSize = this.defaultPageSize

      // console.log(queryObj)
      this.$http.get(this.$apiUrl + 'checkups?where=' + encodeURIComponent(JSON.stringify(queryObj)) + '&hmcNo='+ this.boost+'&count=true&skip=' + (page - 1) * this.pageSize + '&limit=' + this.pageSize + '&calDate=' + this.selDate+ '&all=' + all+ '&cal=true&time=true')
        .then(response => {
          const data = response.data.results

          //console.log(data)

          this.total = response.data.total
          this.currentPage = page

          this.paging = this.paginate(response.data.total, page, this.pageSize, this.maxPages)
          // console.log(this.paging)

          // 초기화

          if (!this.isMobile) {
            self.removeMarkers()
            self.resetCalendarSlide()
            this.mapBounds = new kakao.maps.LatLngBounds()
          }

          self.checkups = []
          self.appointmentCheckups = []
          // console.log(data)

          // 지도를 재설정할 범위정보를 가지고 있을 LatLngBounds 객체를 생성


          for (let i = 0; i < data.length; i++) {

            //data[i] = data[1]


            var lon = data[i].lon
            var lat = data[i].lat

            /*if (lon === 0.0) {
              lon = data[i].lonEtc
              lat = data[i].latEtc
            }*/

            var hmcTpImgStyle = null

            if (data[i].hmcTpImg !== undefined) {
              if (data[i].hmcHomeUrl !== undefined) hmcTpImgStyle = 'background-image: url("' + this.$imgUrl + data[i].hmcTpImg + '");cursor: pointer;'
              else hmcTpImgStyle = 'background-image: url("' + this.$imgUrl + data[i].hmcTpImg + '");'
            }

            var appointmentTime = self.appointmentTimeTables(data[i].appointmentTime)

            var selDate = ''
            if (appointmentTime !== undefined) {
              if(appointmentTime.aTimes.length > 0 || appointmentTime.pTimes.length > 0) selDate = appointmentTime.date
            }
            var selDate = appointmentTime === undefined ? '' : appointmentTime.date


            self.checkups.push({
              id: data[i].id,
              hmcNo: data[i].hmcNo,
              hmcNm: data[i].hmcNm,
              hmcAlias: data[i].hmcAlias,
              hmcTpImg: hmcTpImgStyle,
              hmcHomeUrl: data[i].hmcHomeUrl,
              hospital_id: data[i].hospital_id,
              hmcType: data[i].hmcType,
              hmcTelNo: data[i].hmcTelNo.split(',')[0].split('~')[0].trim(),
              exmdrTelNo: data[i].exmdrTelNo,
              locPostNo: data[i].locPostNo,
              //locAddr: self.shortAddr(data[i].siDoCd, data[i].locAddr),
              locAddr: data[i].locAddrEtc,
              siDoCd: data[i].siDoCd,
              siGunGuCd: data[i].siGunGuCd,
              notice: data[i].notice,
              directionInfo: data[i].directionInfo,
              grenChrgTypeCd: data[i].grenChrgTypeCd,
              ccExmdChrgTypeCd: data[i].ccExmdChrgTypeCd,
              cvxcaExmdChrgTypeCd: data[i].cvxcaExmdChrgTypeCd,
              stmcaExmdChrgTypeCd: data[i].stmcaExmdChrgTypeCd,
              lvcaExmdChrgTypeCd: data[i].lvcaExmdChrgTypeCd,
              bcExmdChrgTypeCd: data[i].bcExmdChrgTypeCd,
              mchkChrgTypeCd: data[i].mchkChrgTypeCd,
              lncaExmdChrgTypeCd: data[i].lncaExmdChrgTypeCd,
              ichkChrgTypeCd: data[i].ichkChrgTypeCd,
              lon: lon,
              lat: lat,
              holidayExmd: data[i].holidayExmd,
              specialtySubject: data[i].specialtySubject,
              clinicalSubject: data[i].clinicalSubject,
              doctor: data[i].doctor,
              gastroscopy: data[i].gastroscopy,
              mammography: data[i].mammography,
              colonoscopy: data[i].colonoscopy,
              ultrasonography: data[i].ultrasonography,
              parkingInfoRegDate: data[i].parkingInfoRegDate,
              parkingLot: data[i].parkingLot,
              freeParking: data[i].freeParking,
              parkingSlots: data[i].parkingSlots,
              parkingExtra: data[i].parkingExtra,
              exmdTimeRegDate: data[i].exmdTimeRegDate,
              exmdTimeInfo: data[i].exmdTimeInfo,
              lunchTimeRegDate: data[i].lunchTimeRegDate,
              lunchTimeInfo: data[i].lunchTimeInfo,
              receiptTimeRegDate: data[i].receiptTimeRegDate,
              receiptTimeInfo: data[i].receiptTimeInfo,
              lonEtc: data[i].lonEtc,
              latEtc: data[i].latEtc,
              subwayInfo: data[i].subwayInfo,
              calendars: self.appointmentCalendars(data[i]),
              appointmentTime: appointmentTime,
              exmdChrg: self.exmdChrgInfo(data[i]),
              statistic: self.hospitalStatistic(data[i].statistic),
              blogUrl: data[i].blogUrl,
              cafeUrl: data[i].cafeUrl,
              kakaoUrl: data[i].kakaoUrl,
              instaUrl: data[i].instaUrl,
              active: data[i].active,
              appointment: data[i].active === false ? false : data[i].appointment,
              selDate : selDate,
            })

            // 예약 가능 병원 목록
            if (data[i].active && data[i].appointment) self.appointmentCheckups.push({id: data[i].id, hospital_id : data[i].hospital_id})


            if (!this.isMobile && lon !== 0.0 && lat !== 0.0) {
              var point = new kakao.maps.LatLng(lat, lon)

              if (data[i].active === true) this.addOverlay(data[i], point)
              else this.addMarker(data[i], point)
              this.mapBounds.extend(point)
            }
          }

          // console.log(self.checkups)
          // console.log(self.appointmentCheckups)

          if (!this.isMobile && data.length > 0) self.map.setBounds(this.mapBounds)
          /*
          this.$nextTick(() => {
            document.getElementById('hospitalList').scrollTop = 0

          })
          */
        })
        .catch(error => {
          console.log(error)
        })
    },
    zoomIn () {
      this.map.setLevel(this.map.getLevel() - 1)
    },
    zoomOut () {
      this.map.setLevel(this.map.getLevel() + 1)
    },
    panTo (lon, lat) {
      // 만약 이동할 거리가 지도 화면보다 크면 부드러운 효과 없이 이동합니다
      this.map.panTo(new kakao.maps.LatLng(lat, lon))
    },
    resetBound () {
      if (this.checkups.length > 0) this.map.setBounds(this.mapBounds)
    },
    onLeftContentOver (hospitalId, over) {
      if (this.markerMap[hospitalId] !== undefined) {
        if (this.markerMap[hospitalId].overlay === null) {
          if (over === true) {
            this.markerMap[hospitalId].marker.setImage(this.createMarkerImage(this.overMarkerSize, this.markerOffset, this.clickOrigin))
            this.markerMap[hospitalId].marker.setZIndex(3)
          } else {
            this.markerMap[hospitalId].marker.setImage(this.createMarkerImage(this.markerSize, this.markerOffset, this.normalOrigin))
            this.markerMap[hospitalId].marker.setZIndex(1)
          }
        } else {
          if (over === true) {
            this.markerMap[hospitalId].overlay.className = 'tooltip_hover'
            this.markerMap[hospitalId].marker.setZIndex(3)
          } else {
            this.markerMap[hospitalId].overlay.className = 'tooltip'
            this.markerMap[hospitalId].marker.setZIndex(1)
          }
        }
      }
    },
    openHomePage (idx) {
      if (this.checkups[idx].hmcHomeUrl !== undefined) window.open(this.checkups[idx].hmcHomeUrl, '_blank')
    },
    copyToClipBoard (str) {
      const el = document.createElement('textarea')
      el.value = str
      el.setAttribute('readonly', '')
      el.style.position = 'absolute'
      el.style.left = '-9999px'
      document.body.appendChild(el)
      el.select()
      document.execCommand('copy')
      document.body.removeChild(el)
    },
    openMap (idx, type) {
      const url = 'https://map.kakao.com/link/' + type + '/' + encodeURIComponent(this.checkups[idx].hmcNm) + ',' + this.checkups[idx].lat + ',' + this.checkups[idx].lon
      window.open(url, '_blank')
    },
    moveMapTo (idx, hospitalId) {
      if (this.screenWidth > 980) {
        this.map.setLevel(2)

        if (this.markerMap[hospitalId].overlay === null) { // 객체가 마커인 경우
          var clickImage = this.createMarkerImage(this.markerSize, this.markerOffset, this.normalOrigin)

          // this.panTo(this.markerMap[hospitalId].marker.getPosition().La, this.markerMap[hospitalId].marker.getPosition().Ma)
          this.map.setCenter(new kakao.maps.LatLng(this.markerMap[hospitalId].marker.getPosition().Ma, this.markerMap[hospitalId].marker.getPosition().La))
          this.showInfoWindow(this.markerMap[hospitalId].marker, clickImage, this.markerMap[hospitalId].item)
        } else this.map.setCenter(new kakao.maps.LatLng(this.markerMap[hospitalId].marker.getPosition().Ma, this.markerMap[hospitalId].marker.getPosition().La))
      } else if (idx !== -1) {
        this.openMap(idx, 'map')
      }
    },
    showInfoWindow (marker, clickImage, item) {
      // 클릭된 마커가 없고, click 마커가 클릭된 마커가 아니면
      // 마커의 이미지를 클릭 이미지로 변경합니다

      if (!this.selectedMarker || this.selectedMarker !== marker) {
        // 클릭된 마커 객체가 null이 아니면
        // 클릭된 마커의 이미지를 기본 이미지로 변경하고
        !!this.selectedMarker && this.selectedMarker.setImage(this.selectedMarker.normalImage)

        // 현재 클릭된 마커의 이미지는 클릭 이미지로 변경합니다
        marker.setImage(clickImage)
      }

      // 클릭된 마커를 현재 클릭된 마커 객체로 설정합니다
      this.selectedMarker = marker

      // 기존 오버레이 삭제
      if (this.mapOverlay != null) this.mapOverlay.setMap(null)

      this.map.setLevel(2)
      // var iwContent = '<div style="padding:5px;">' + item.hmcAlias + '<br><a href="https://map.kakao.com/link/map/Hello World!,33.450701,126.570667" style="color:blue" target="_blank">큰지도보기</a> <a href="https://map.kakao.com/link/to/Hello World!,33.450701,126.570667" style="color:blue" target="_blank">길찾기</a></div>'
      // var iwContent = '<div style="padding:5px;text-align: center">' + item.hmcAlias + '</div>'

      // 인포윈도우를 생성
      // this.mapOverlay = new kakao.maps.InfoWindow({ position: marker.getPosition(), content: iwContent })

      // 마커 위에 인포윈도우를 표시합니다. 두번째 파라미터인 marker를 넣어주지 않으면 지도 위에 표시됩니다
      // this.mapOverlay.open(this.map, marker)
    },

    // target node에 이벤트 핸들러를 등록하는 함수힙니다
    addEventHandle (target, type, callback) {
      if (target.addEventListener) {
        target.addEventListener(type, callback)
      } else {
        target.attachEvent('on' + type, callback)
      }
    },
    // target node에 등록된 이벤트 핸들러를 제거하는 함수힙니다
    removeEventHandle (target, type, callback) {
      if (target.removeEventListener) {
        target.removeEventListener(type, callback)
      } else {
        target.detachEvent('on' + type, callback)
      }
    },

    // 마커를 생성하고 지도 위에 표시하고, 마커에 mouseover, mouseout, click 이벤트를 등록
    addOverlay (item, position) {
      const self = this

      var content = document.createElement('div')
      content.className = 'tooltip'
      content.innerText = item.hmcAlias
      // 커스텀 오버레이를 생성합니다
      var mapCustomOverlay = new kakao.maps.CustomOverlay({
        map: this.map,
        position: position,
        content: content,
        xAnchor: 0.125, // 커스텀 오버레이의 x축 위치입니다. 1에 가까울수록 왼쪽에 위치합니다. 기본값은 0.5 입니다
        yAnchor: 1.2 // 커스텀 오버레이의 y축 위치입니다. 1에 가까울수록 위쪽에 위치합니다. 기본값은 0.5 입니다
      })

      self.markerMap[item.id] = { marker: mapCustomOverlay, item: item, overlay: content }

      self.addEventHandle(content, 'click', function () {
        // 커스텀오버레이에서 마우스 관련 이벤트가 발생해도 지도가 움직이지 않도록 합니다
        kakao.maps.event.preventMap()
        self.moveMapTo(-1, item.id)
      })

      self.addEventHandle(content, 'mouseover', function () {
        // 커스텀오버레이에서 마우스 관련 이벤트가 발생해도 지도가 움직이지 않도록 합니다
        kakao.maps.event.preventMap()
        content.className = 'tooltip_hover'
        mapCustomOverlay.setZIndex(3)
      })

      self.addEventHandle(content, 'mouseout', function () {
        // 커스텀오버레이에서 마우스 관련 이벤트가 발생해도 지도가 움직이지 않도록 합니다
        kakao.maps.event.preventMap()
        content.className = 'tooltip'
        mapCustomOverlay.setZIndex(1)
      })
    },
    // 마커를 생성하고 지도 위에 표시하고, 마커에 mouseover, mouseout, click 이벤트를 등록
    addMarker (item, position) {
      const self = this

      // 기본 마커이미지, 오버 마커이미지, 클릭 마커이미지를 생성
      var normalImage = this.createMarkerImage(this.markerSize, this.markerOffset, this.normalOrigin)
      var overImage = this.createMarkerImage(this.overMarkerSize, this.markerOffset, this.clickOrigin)

      // 마커를 생성하고 이미지는 기본 마커 이미지를 사용합니다
      var marker = new kakao.maps.Marker({
        map: this.map,
        position: position,
        image: normalImage
      })

      // 마커 객체에 마커아이디와 마커의 기본 이미지를 추가합니다
      marker.normalImage = normalImage

      // 마커에 mouseover 이벤트를 등록합니다
      kakao.maps.event.addListener(marker, 'mouseover', function () {
        marker.setImage(overImage)
        marker.setZIndex(3)
        /*
        // 클릭된 마커가 없고, mouseover된 마커가 클릭된 마커가 아니면
        // 마커의 이미지를 오버 이미지로 변경합니다
        if (!self.selectedMarker || self.selectedMarker !== marker) {
          marker.setImage(overImage)
        }
        */
      })

      // 마커에 mouseout 이벤트를 등록합니다
      kakao.maps.event.addListener(marker, 'mouseout', function () {
        marker.setImage(normalImage)
        marker.setZIndex(1)
        /*
        // 클릭된 마커가 없고, mouseout된 마커가 클릭된 마커가 아니면
        // 마커의 이미지를 기본 이미지로 변경합니다
        if (!self.selectedMarker || self.selectedMarker !== marker) {
          marker.setImage(normalImage)
        }
        */
      })

      // 마커에 click 이벤트를 등록합니다
      kakao.maps.event.addListener(marker, 'click', function () {
        self.moveMapTo(-1, item.id)
      })

      self.markerMap[item.id] = { marker: marker, item: item, overlay: null }
    },
    // MakrerImage 객체를 생성하여 반환하는 함수
    createMarkerImage (markerSize, offset, spriteOrigin) {
      var markerImage = new kakao.maps.MarkerImage(
        this.SPRITE_MARKER_URL, // 스프라이트 마커 이미지 URL
        markerSize, // 마커의 크기
        {
          offset: offset, // 마커 이미지에서의 기준 좌표
          spriteOrigin: spriteOrigin, // 스트라이프 이미지 중 사용할 영역의 좌상단 좌표
          spriteSize: this.spriteImageSize // 스프라이트 이미지의 크기
        }
      )

      return markerImage
    },
    removeMarkers () {
      for (const key in this.markerMap) {
        this.markerMap[key].marker.setMap(null)
      }

      this.markerMap = {}
      this.selectedMarker = null
      this.mapOverlay = null
    },

    appointmentTimeTables(appointmentTime) {

      if(appointmentTime == undefined) return appointmentTime

      const res = { date : appointmentTime.date, nextDate : appointmentTime.nextDate, picked : null, aTimes : [] , pTimes :[]}

      for(let i = 0; i < appointmentTime.times.length; i++) {
        if(parseInt(appointmentTime.times[i].time.substr(0,2)) < 12) {
          res.aTimes.push(appointmentTime.times[i])
        } else {
          res.pTimes.push(appointmentTime.times[i])
        }
      }
      // console.log(res)
      return res
    },

    loadHeadCalendar () {

      var calDate = this.$moment(this.date).add(1, 'day').toDate()

      for (let i = 1; i <= 90; i++) {
        var date = this.$moment(this.date).add(i, 'day').format('YYYYMMDD')
        if (i <= 10) this.mCalendarHeads.push({ date : date, diff : i})
        this.mCalendarDates.push({ date : date, diff : i})
      }

      for (let i = 0; i < this.calendarMaxIdx; i++) {
        this.calendarHeads.push({
          year: calDate.getFullYear(),
          month: (calDate.getMonth() + 1),
        })

        if (calDate.getMonth() === 11) {
          calDate = new Date(calDate.getFullYear() + 1, 0, 1);
        } else {
          calDate = new Date(calDate.getFullYear(), calDate.getMonth() + 1, 1);
        }
      }

      // console.log(this.calendarHeads)

    },
    appointmentCalendars (item) {
      const res = []
      let calDate = this.$moment(this.date).add(1, 'day').toDate()
      let year = parseInt(this.selDate.substr(0, 4))
      let month = parseInt(this.selDate.substr(4, 2))

      for (let i = 0; i < this.calendarMaxIdx; i++) { // 캘린더 달력 생성

        res.push({
          year: calDate.getFullYear(),
          month: (calDate.getMonth() + 1),
          days: (calDate.getFullYear() == year && (calDate.getMonth() + 1) == month) ? (item.appointment && item.active ? this.buildCalendar(item.appointmentCal, calDate) : this.dummyCalendar(calDate)) : null
        })

        if (calDate.getMonth() === 11) {
          calDate = new Date(calDate.getFullYear() + 1, 0, 1);
        } else {
          calDate = new Date(calDate.getFullYear(), calDate.getMonth() + 1, 1);
        }

      }
      // console.log(res)
      return res
    },

    /* 월별 예약 달력 생성 */
    buildCalendar (appointmentCal, calDate) {
      const res = [[]]

      // console.log(item)

      var doMonth = new Date(calDate.getFullYear(), calDate.getMonth(), 1)
      var lastDate = new Date(calDate.getFullYear(), calDate.getMonth() + 1, 0)

      var nowday = this.$moment(this.date.getFullYear() + ('0' + (this.date.getMonth() + 1)).slice(-2) + ('0' + this.date.getDate()).slice(-2), 'YYYYMMDD')


      for(var i = 0; i < appointmentCal.days.length ; i++) {

        if(i !== 0 ) res.push([])

        var passedDay = 0

        for(var j = 0; j < appointmentCal.days[i].length; j++) {
          var day = appointmentCal.days[i][j];
          var diffday = -100
          var radius = ''


          if(day.day !== 0) {
            // console.log(item.appointmentCal.year + ('0' + item.appointmentCal.month).slice(-2) + ('0' + item.appointmentCal.day).slice(-2))
            var date = appointmentCal.year + ('0' + appointmentCal.month).slice(-2) + ('0' + day.day).slice(-2)
            var calday = this.$moment(date, 'YYYYMMDD')
            diffday = calday.diff(nowday, 'days')

            if(diffday <= 0) {
              if(passedDay === 0) {
                if(j === appointmentCal.days[i].length-1) radius = 'border-radius: 5px;'
                else if(diffday === 0) radius = 'border-radius: 5px;'
                else radius = 'border-radius: 5px 0px 0px 5px;'
              } else {
                if(j === appointmentCal.days[i].length-1 || diffday === 0) radius = 'border-radius: 0px 5px 5px 0px;'
              }
            }
            passedDay++

          }
          res[i].push({ day: day.day, allow: day.allow, diffDay: diffday, radius : radius, date: date })
        }
      }

      while (res[res.length - 1].length < 7) {
        res[res.length - 1].push({ day: 0, allow: false, diffDay: -100, radius : radius, date: null })
      }

      // console.log(res)

      return res
    },

    /* 더미 예약 달력 생성 */
    dummyCalendar (calDate) {
      const res = [[]]

      var doMonth = new Date(calDate.getFullYear(), calDate.getMonth(), 1)
      var lastDate = new Date(calDate.getFullYear(), calDate.getMonth() + 1, 0)

      let cnt = 0
      for (let i = 0; i < doMonth.getDay(); i++) {
        res[res.length - 1].push({ day: 0, allow: false, diffDay: -100, select: false })
        cnt++
      }

      var nowday = this.$moment(this.date.getFullYear() + ('0' + (this.date.getMonth() + 1)).slice(-2) + ('0' + this.date.getDate()).slice(-2), 'YYYYMMDD')

      var passedDay = 0

      for (let i = 1; i <= lastDate.getDate(); i++) {
        // let tday = false
        // const book = false
        var calday = this.$moment(calDate.getFullYear() + ('0' + (calDate.getMonth() + 1)).slice(-2) + ('0' + i).slice(-2), 'YYYYMMDD')

        var diffday = calday.diff(nowday, 'days')

        // res[res.length - 1].push({ day: i, allow: false, diffDay: diffday, select: false })
        var radius = ''

        if (cnt % 7 === 0) {
          if(diffday <= 0) {
            if(passedDay === 0) {
              radius = 'border-radius: 5px;'
            } else {
              radius = 'border-radius: 0px 5px 5px 0px;'
            }
          }
          res[res.length - 1].push({ day: i, allow: false, diffDay: diffday, radius : radius })
          passedDay = 0
        } else {

          var allow = false

          // if (diffday <= 90 && diffday > 0) allow = true

          if(diffday <= 0) {
            if(passedDay === 0) {
              if(diffday === 0) radius = 'border-radius: 5px;'
              else radius = 'border-radius: 5px 0px 0px 5px;'
            } else {
              if(diffday === 0) radius = 'border-radius: 0px 5px 5px 0px;'
            }
          }
          res[res.length - 1].push({ day: i, allow: allow, diffDay: diffday, radius : radius })
          passedDay++
        }

        cnt++

        // 일요일
        if (cnt % 7 === 0) {
          res.push([])
        }

      }

      while (res[res.length - 1].length < 7) {
        res[res.length - 1].push({ day: 0, allow: false, diffDay: -100, select: false })
      }

      return res
    },

    getAppointmentTime(checkupIdx, date) {

      const self = this

      this.checkups[checkupIdx].selDate = date

      this.$http.get(this.$apiUrl + 'checkups/' + this.checkups[checkupIdx].id + '/appointment/schedules/' + date.substr(0, 4) + '/' + date.substr(4, 2) + '/' + date.substr(6, 2))
        .then(response => {
          self.checkups[checkupIdx].appointmentTime = self.appointmentTimeTables(response.data)
        })
        .catch(error => {
          self.checkups[checkupIdx].appointmentTime = undefined
          console.log(error)
        })
    },

    requestFindAppointment () {
      // this.phone.replace(new RegExp('-', 'g'), '')
      console.log('look up')

      const self = this

      this.$http.get(this.$apiUrl + 'appointments/' + this.appointmentNo + '/' + (this.prefix_phone + this.appointmentPhone.replace(new RegExp('-', 'g'), '') ))
        .then(response => {
          // console.log(response.data)


          self.appointmentInfo = response.data
          self.appointmentInfo.checkup.hmcTelNo = self.appointmentInfo.checkup.hmcTelNo.split(',')[0].split('~')[0].trim()

          self.appointmentInfo.checkup['exmdChrg'] =  self.exmdChrgInfo(self.appointmentInfo.checkup)

          var hmcTpImgStyle = null

          if (self.appointmentInfo.checkup.hmcTpImg !== undefined) {
            if (self.appointmentInfo.checkup.hmcHomeUrl !== undefined) hmcTpImgStyle = 'background-image: url("' + self.$imgUrl + self.appointmentInfo.checkup.hmcTpImg + '");cursor: pointer;'
            else hmcTpImgStyle = 'background-image: url("' + self.$imgUrl + self.appointmentInfo.checkup.hmcTpImg + '");'
          }

          self.appointmentInfo.checkup.hmcTpImg = hmcTpImgStyle

          self.openViewAppointment()
          //if (self.appointmentInfo.checkup.active && self.appointmentInfo.checkup.appointment) self.openViewAppointment()
          //else self.findAppointmentNoneActive = true

          //console.log('appointmentInfo')
          //console.log(self.appointmentInfo)

          //self.openCtAlert({ code : 1, openCancel : true, text : '인증번호'})

        })
        .catch(error => {
          if (error.response) {
            self.findAppointmentMsg = error.response.data.error_description
            // self.stopTimer()
            // self.isSmsVeriComplete = true

          }

          console.log(error)
        })

    },

    /*
    bookingDate (checkupIdx, calIdx, wIdx, dIdx, day) {
      // 기존 선택이 있는 경우 선택 제거
      if (this.selectetCalendar.idx !== -1) this.checkups[this.selectetCalendar.idx].calendars[this.selectetCalendar.calIdx].days[this.selectetCalendar.wIdx][this.selectetCalendar.dIdx].select = false

      this.selectetCalendar.idx = checkupIdx
      this.selectetCalendar.calIdx = calIdx
      this.selectetCalendar.wIdx = wIdx
      this.selectetCalendar.dIdx = dIdx

      day.select = true

      $('#preparing' + (checkupIdx + 1)).fadeIn(500).delay(1000).fadeOut(500)
    },
    */

    shortAddr (sidoCd, val) {
      let res = val.replace(/ *\([^)]*\) */g, '')
      const arr = res.split(' ')

      if (this.shortAddrTable[sidoCd] !== undefined) {
        res = this.shortAddrTable[sidoCd]
        for (let i = 1; i < arr.length; i++) {
          if (arr[i].includes(',') === true) break
          res += ' ' + arr[i]
        }
      }
      return res
    },
    clinicalSubjectStr (val) {
      let res = ''
      let ellipsis = ''
      const arr = val.split(',')

      let length = arr.length

      if (length > 5) {
        length = 5
        ellipsis = ' ...'
      }

      for (let i = 0; i < length; i++) {
        if (i === 0) res = arr[i]
        else res += ',' + arr[i]
      }

      return res + ellipsis
    },
    exmdChrgInfo (item) {
      let str = ''
      let aImgCls = 'img '
      let aImgHtml = ''
      let gren = ''
      let cancer = ''
      let wcancer = ''

      /*
      str = '일반검진'
      aImgHtml = '일반·암<br/><span>검진</span>'
      aImgCls += 'img3'


      return { str: str, aImgCls: aImgCls, aImgHtml: aImgHtml }
      */

      if (item.grenChrgTypeCd === '1') gren = '일반검진'

      if (item.stmcaExmdChrgTypeCd === '1') cancer += (cancer === '' ? '암(위암' : ',위암')
      if (item.ccExmdChrgTypeCd === '1') cancer += (cancer === '' ? '암(대장암' : ',대장암')
      if (item.lvcaExmdChrgTypeCd === '1') cancer += (cancer === '' ? '암(간암' : ',간암')
      if (item.lncaExmdChrgTypeCd === '1') cancer += (cancer === '' ? '암(폐암' : ',폐암')
      if (cancer !== '') cancer += ')'

      if (item.bcExmdChrgTypeCd === '1') wcancer += wcancer === '' ? '여성(유방암' : ',유방암'
      if (item.cvxcaExmdChrgTypeCd === '1') wcancer += wcancer === '' ? '여성(자궁경부암' : ',자궁경부암'
      if (wcancer !== '') wcancer += ')'

      if (cancer !== '') str = gren !== '' ? (gren + ', ' + cancer) : cancer
      else str = gren

      if (wcancer !== '') str = str !== '' ? (str + ', ' + wcancer) : wcancer

      if (gren !== '') {
        if (cancer !== '' && wcancer !== '') {
          aImgHtml = '일반·암<span class="span_v2">여성</span><span>검진</span>'
          aImgCls += 'img5'
        } else if (cancer !== '') {
          aImgHtml = '일반·암<br/><span>검진</span>'
          aImgCls += 'img3'
        } else if (wcancer !== '') {
          aImgHtml = '일반·여성<span>검진</span>'
          aImgCls += 'img6'
        } else {
          aImgHtml = '일반<br/><span>검진</span>'
          aImgCls += 'img2'
        }
      } else {
        if (cancer !== '') {
          if (wcancer !== '') {
            aImgHtml = '암·여성<span>검진</span>'
            aImgCls += 'img7'
          } else {
            aImgHtml = '암<br /><span>검진</span>'
            aImgCls += 'img4'
          }
        } else {
          aImgHtml = '여성<span>검진</span>'
          aImgCls += 'img8'
        }
      }
      return { str: str, aImgCls: aImgCls, aImgHtml: aImgHtml }
    },
    hospitalStatistic (val) {
      const res = []

      if (val === undefined) return res

      const infoArr = val.split('^')

      for (var i = 0; i < infoArr.length; i++) {
        const statisticArr = infoArr[i].split('|')
        for (var j = 0; j < statisticArr.length; j++) {
          res.push({ title: statisticArr[j], rate: 3 - j })
        }
      }

      return res
    },
    subwayInfoStr (subwayInfo) {
      let res = ''

      const infoArr = subwayInfo.split('|')

      const lineTypeArr = infoArr[0].split('^') // 지하철 호선 목록

      try {
        for (var i = 0; i < lineTypeArr.length; i++) {
          if (this.subwayTypeTable[lineTypeArr[i]].dispName.length === 1) res += '<span class="train_btn" style="background: ' + this.subwayTypeTable[lineTypeArr[i]].color + ';">' + this.subwayTypeTable[lineTypeArr[i]].dispName + '</span>'
          else res += '<span class="train_btn train_btn_txt" style="background: ' + this.subwayTypeTable[lineTypeArr[i]].color + ';">' + this.subwayTypeTable[lineTypeArr[i]].dispName + '</span>'
          // subwayTypeTable[type].dispName
        }

        res += '<span>' + infoArr[1] + '역</span><span class="gray">' + infoArr[2] + '번 출구에서</span><span class="red">' + infoArr[3] + 'm</span>'
      } catch (e) {
        res = ''
      }
      return res
    },
    setFilter (idx, isSub) {
      if (this.searchOptions.items[idx].child === null) this.searchOptions.items[idx].selected = true // 서브 필터가 없는 경우 선택 처리
      else {
        // 서브 필터 선택이 아닌경우 서브 필터 창 노출
        if (isSub === false) this.searchOptions.items[idx].showChild = true
        else {
          // this.searchOptions.items[idx].child[sIdx].selected = true
          this.searchOptions.items[idx].text = ''

          for (let i = 0; i < this.searchOptions.items[idx].subSettings.length; i++) {
            this.searchOptions.items[idx].child[this.searchOptions.items[idx].subSettings[i]].selected = true
            if (this.searchOptions.items[idx].text === '') this.searchOptions.items[idx].text = this.searchOptions.items[idx].child[this.searchOptions.items[idx].subSettings[i]].text
            else this.searchOptions.items[idx].text += ', ' + this.searchOptions.items[idx].child[this.searchOptions.items[idx].subSettings[i]].text
            // this.searchOptions.items[idx].child[this.searchOptions.items[idx].subSettings[i]].text
          }

          this.searchOptions.items[idx].selected = true

          this.searchOptions.items[idx].showChild = false
        }
      }

      this.hlink = false

      this.hospitalSearch(this.siDoCd, this.siGunGuCds, 1)
    },
    removeFilter (idx) {
      this.searchOptions.items[idx].selected = false

      if (this.searchOptions.items[idx].child !== null) { // 서브 필터가 있는 경우 서브필터 초기화
        this.searchOptions.items[idx].text = this.searchOptions.items[idx].orgText // 기존 값으로 변경
        this.searchOptions.items[idx].subSettings = [] // 서브 세팅 초기화
        for (let i = 0; i < this.searchOptions.items[idx].child.length; i++) {
          this.searchOptions.items[idx].child[i].selected = false
        }
      }

      this.hlink = false

      this.hospitalSearch(this.siDoCd, this.siGunGuCds, 1)
    },

  }
}
</script>

<template>
  <div class="inner">
    <div class="popup_title">
      <h5>희망 검진일 선택</h5>
      <button class="popup_close" @click="close()">x</button>
    </div>
    <div class="calendar">

      <swiper
        class="cal_month2"
        ref="calMonthSlide"
        :options="calMonthOption"
      >
        <!--@slide-change="mCalHeadSlideChange"-->
        <swiper-slide v-for="(head) in calendarHeads" >
          {{head.year + '. ' + padLeft(String(head.month),2)}}
        </swiper-slide>
        <div class="swiper_btn_box swiper-button-prev_4" slot="button-prev"><font-awesome-icon icon="fa-solid fa-chevron-left" /></div>
        <div class="swiper_btn_box swiper-button-next_4" slot="button-next"><font-awesome-icon icon="fa-solid fa-chevron-right" /></div>
      </swiper>
      <div class="week">
        <p>일</p>
        <p>월</p>
        <p>화</p>
        <p>수</p>
        <p>목</p>
        <p>금</p>
        <p>토</p>
      </div>
      <swiper
        class="pop_cal_date"
        ref="calDateSlide"
        :options="calDateOption"
      >
        <!--@slide-change="mCalHeadSlideChange"-->
        <swiper-slide v-for="(calendar, calIdx) in calendars" >
          <div class="date_box">
            <div v-for="(week, wIdx) in calendar.days" class="date">
              <template v-for="(day, dIdx) in week">
                <button v-if="day.day === 0" type="button" class="blank"></button>
                <button v-else-if="day.diffDay <= 0" type="button" class="last_week"><p :style="day.radius">{{day.day}}</p><span v-if="day.diffDay === 0">오늘</span></button>
                <button v-else-if="day.allow === true" :class="day.diffDay === 1 ? (selDate === day.date ? 'tomorrow active' : 'tomorrow'): (selDate === day.date ? 'active' : '')" type="button" @click="setDate(day.date)"><p>{{day.day}}</p><span v-if="day.diffDay === 1">내일</span></button>
                <button v-else type="button" :class="day.diffDay === 1 ? 'tomorrow' : ''" disabled><p>{{day.day}}</p><span v-if="day.diffDay === 1">내일</span></button>
              </template>
            </div>
            <!--@click="appointmentDate(index, calIdx, wIdx, dIdx, day)"-->
          </div>

        </swiper-slide>
      </swiper>

    </div>
    <div class="btn_box">
      <button class="apply_btn" @click="close()">설정 완료</button>
    </div>
  </div>
</template>

<script>

  import { swiper, swiperSlide } from 'vue-awesome-swiper'
  import 'swiper/dist/css/swiper.css'

  export default {
    name: 'Calendar',
    components: {
      swiper,
      swiperSlide,
    },
    props: {
      calendarHeads: Array,
      today: Date,
      baseDate: String,
    },
    data () {
      return {
        calendars : [[]],
        selDate: null,
        calMonthOption : {
          spaceBetween: 10,
          initialSlide: 0,
          /*
          navigation: {
            prevEl: '.swiper-button-prev_4',
            nextEl: '.swiper-button-next_4'
          }*/
        },
        calDateOption :{
          spaceBetween: 0,
          initialSlide: 0,
          navigation: {
            prevEl: ".swiper-button-prev_4",
            nextEl: ".swiper-button-next_4",
          },
          centeredSlides: true,
          slidesPerView: 1,
          touchRatio: 0.2,
          slideToClickedSlide: true,
        }
      }
    },
    created: function () {
      this.selDate = this.baseDate
      this.calendars = this.loadCalendars()

      // console.log(this.baseDate)

    },
    mounted: function () {
      this.$nextTick(() => {

        const monthSlide = this.$refs.calMonthSlide
        const dateSlide = this.$refs.calDateSlide
        monthSlide.swiper.controller.control = dateSlide.swiper
        dateSlide.swiper.controller.control = monthSlide.swiper

      })
    },
    methods: {
      padLeft (n, width, z) {
        z = z || '0'
        n = n + ''
        return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n
      },
      close() {
        this.$emit('close', this.selDate);
      },

      loadCalendars () {
        const res = []
        let calDate = this.$moment(this.today).add(1, 'day').toDate()
        let year = parseInt(this.selDate.substr(0, 4))
        let month = parseInt(this.selDate.substr(4, 2))

        for (let i = 0; i < this.calendarHeads.length; i++) { // 캘린더 달력 생성

          let calYear = calDate.getFullYear()
          let calMonth = (calDate.getMonth() + 1);

          if(calYear == year && calMonth == month) {
            this.calMonthOption.initialSlide = i
            this.calDateOption.initialSlide = i
          }

          res.push({
            year: calYear,
            month: calMonth,
            days: this.buildCalendar(calDate)
          })

          if (calDate.getMonth() === 11) {
            calDate = new Date(calYear + 1, 0, 1);
          } else {
            calDate = new Date(calYear, calMonth, 1);
          }

        }
        // console.log(res)
        return res
      },

      /* 달력 생성 */
      buildCalendar (calDate) {
        const res = [[]]

        var doMonth = new Date(calDate.getFullYear(), calDate.getMonth(), 1)
        var lastDate = new Date(calDate.getFullYear(), calDate.getMonth() + 1, 0)

        let cnt = 0
        for (let i = 0; i < doMonth.getDay(); i++) {
          res[res.length - 1].push({ day: 0, allow: false, diffDay: -100})
          cnt++
        }

        var nowday = this.$moment(this.today.getFullYear() + ('0' + (this.today.getMonth() + 1)).slice(-2) + ('0' + this.today.getDate()).slice(-2), 'YYYYMMDD')

        var passedDay = 0

        for (let i = 1; i <= lastDate.getDate(); i++) {
          // let tday = false
          // const book = false

          var date = calDate.getFullYear() + ('0' + (calDate.getMonth() + 1)).slice(-2) + ('0' + i).slice(-2)
          var calday = this.$moment(date, 'YYYYMMDD')

          var diffday = calday.diff(nowday, 'days')

          // res[res.length - 1].push({ day: i, allow: false, diffDay: diffday, select: false })
          var radius = ''

          if (cnt % 7 === 0) {
            if(diffday <= 0) {
              if(passedDay === 0) {
                radius = 'border-radius: 5px;'
              } else {
                radius = 'border-radius: 0px 5px 5px 0px;'
              }
            }
            res[res.length - 1].push({ day: i, allow: true, diffDay: diffday, radius : radius, date : date })
            passedDay = 0
          } else {

            var allow = false

            if (diffday <= 90 && diffday > 0) allow = true

            if(diffday <= 0) {
              if(passedDay === 0) {
                if(diffday === 0) radius = 'border-radius: 5px;'
                else radius = 'border-radius: 5px 0px 0px 5px;'
              } else {
                if(diffday === 0) radius = 'border-radius: 0px 5px 5px 0px;'
              }
            }
            res[res.length - 1].push({ day: i, allow: allow, diffDay: diffday, radius : radius, date : date })
            passedDay++
          }

          cnt++

          // 일요일
          if (cnt % 7 === 0) {
            res.push([])
          }

        }

        while (res[res.length - 1].length < 7) {
          res[res.length - 1].push({ day: 0, allow: false, diffDay: -100, select: false })
        }

        return res
      },

      setDate(date) {
        this.selDate = date
      },
    },

  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>

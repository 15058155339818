<template>
  <div class="inner">
    <div class="top_menu" :style="step === 4 ? 'height:40px;' : ''">
      <div class="popup_title">
        <swiper
          ref="stepSlide"
          :options="stepOption"
        >
          <!--@slide-change="mCalHeadSlideChange"-->
          <swiper-slide>
            <h5>희망 검진일 선택</h5>
          </swiper-slide>
          <swiper-slide>
            <h5>희망 시간 및 검진종류 선택</h5>
          </swiper-slide>
          <swiper-slide>
            <h5>예약자정보</h5>
          </swiper-slide>
          <swiper-slide>
            <h5 style="border-bottom: none;"></h5>
          </swiper-slide>
        </swiper>
        <button class="popup_close" @click="close">x</button>
      </div>
      <div v-if="step !== 4" class="title">
        <div class="flex_box">
          <div class="company_img">
            <div v-if="checkup.active && checkup.hmcTpImg" class="img img1" :style="checkup.hmcTpImg"></div>
            <div v-else :class="checkup.exmdChrg.aImgCls">
              <p v-html="checkup.exmdChrg.aImgHtml"></p>
            </div>
          </div>
          <div class="company_desc">
            <div class="overflow">
              <h3>
                <p>{{checkup.hmcNm}}</p>
                <span class="gray">({{checkup.hmcTelNo}})</span>
              </h3>
              <div class="icon_box">
                <a href="#" class="blog_off"></a>
                <a href="#" class="cafe_off"></a>
                <a href="#" class="insta_off"></a>
                <a href="#" class="kakao_off"></a>
              </div>
            </div>
            <p>
              <span class="yellow">{{checkup.exmdChrg.str}}</span>
            </p>
            <div class="address">
              <a v-if="checkup.lon != 0 && checkup.lat != 0" href="#0" @click.prevent="openMap('map')" class="gray">{{checkup.locAddr}}
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 18" class="addr-tool-ico" role="presentation"><path d="M15 6.97a6.92 6.92 0 0 1-1.12 3.77l-5.51 7.08a.47.47 0 0 1-.74 0L2.1 10.71A6.93 6.93 0 0 1 1 6.97 7 7 0 0 1 8 0v.93V0a7 7 0 0 1 7 6.97zm-13 0c0 1.15.4 2.3.99 3.24L8 16.7l5.04-6.5A5.95 5.95 0 0 0 8 1C4.66 1 2 3.64 2 6.97zm6-1.54A1.58 1.58 0 0 0 8 8.6a1.57 1.57 0 0 0 0-3.16zm0-.93a2.51 2.51 0 0 1 0 5.02A2.51 2.51 0 1 1 8 4.5z"></path></svg>
              </a>
              <a v-else href="#" class="gray">{{checkup.locAddr}}</a>
            </div>
          </div>
        </div>
        <div class="date">
          <p>{{selDate !== '' ? $moment(selDate).format('YYYY.MM.DD(dd)') : '날짜를 선택하세요.'}}<span v-show="step > 1">{{selTimeDis}}</span></p>
          <span v-show="step > 1"> {{selItemsDis}}</span>

          <!-- <div class="sel_box">
              <a href="#0"
                  >일반<span><img src="./images/sel_close.png" alt="" /></span
              ></a>

              <a href="#0"
                  >자궁경부암검진<span><img src="./images/sel_close.png" alt="" /></span
              ></a>

              <a href="#0"
                  >추가검진<span><img src="./images/sel_close.png" alt="" /></span
              ></a>
          </div> -->
        </div>
      </div>
    </div>
    <div v-if="step === 1" class="step1">
      <div class="calendar">
        <swiper
          class="cal_month"
          ref="calMonthSlide"
          :options="calMonthOption"
        >
          <!--@slide-change="mCalHeadSlideChange"-->
          <swiper-slide v-for="(head) in calendarHeads" >
            {{head.year + '. ' + padLeft(String(head.month),2)}}
          </swiper-slide>
          <div class="swiper_btn_box swiper-button-prev" slot="button-prev"><font-awesome-icon icon="fa-solid fa-chevron-left" /></div>
          <div class="swiper_btn_box swiper-button-next" slot="button-next"><font-awesome-icon icon="fa-solid fa-chevron-right" /></div>
        </swiper>
        <div class="week">
          <p>일</p>
          <p>월</p>
          <p>화</p>
          <p>수</p>
          <p>목</p>
          <p>금</p>
          <p>토</p>
        </div>
        <swiper
          class="cal_date"
          ref="calDateSlide"
          :options="calDateOption"
          @slideChange="calDateSlideChange"
        >
          <swiper-slide v-for="(calendar, calIdx) in calendars" >
            <div class="date_box">
              <div v-for="(week, wIdx) in calendar.days" class="date">
                <template v-for="(day, dIdx) in week">
                  <button v-if="day.day === 0" type="button" class="blank"></button>
                  <button v-else-if="day.diffDay <= 0" type="button" class="last_week"><p :style="day.radius">{{day.day}}</p><span v-if="day.diffDay === 0">오늘</span></button>
                  <button v-else-if="day.allow === true" :class="day.diffDay === 1 ? (selDate === day.date ? 'tomorrow active' : 'tomorrow'): (selDate === day.date ? 'active' : '')" type="button" @click="setDate(day.date)"><p>{{day.day}}</p><span v-if="day.diffDay === 1">내일</span></button>
                  <button v-else type="button" :class="day.diffDay === 1 ? 'tomorrow' : ''" disabled><p>{{day.day}}</p><span v-if="day.diffDay === 1">내일</span></button>
                </template>
              </div>
            </div>
          </swiper-slide>
        </swiper>
        <div class="calendar_desc">
          <p><span></span>선택</p>
          <p><span></span>불가</p>
        </div>
      </div>

      <div class="btn_box">
        <button class="next" :disabled="selDate === ''" @click="goTo(2)">다음</button>
      </div>
    </div>
    <div v-else-if="step === 2" class="step2">

      <div v-if="times && (times.aTimes.length > 0 || times.pTimes.length > 0)" class="time">
        <div v-if="times.aTimes.length > 0"class="am">
          <p>오전</p>
          <div class="chk_box">
            <div v-for="(tItem, tIdx) in times.aTimes">
              <input v-if="tItem.allow" type="radio" :id="'aTime_' + tIdx" :value="tItem.time" v-model="selTime"/>
              <input v-else type="radio" :id="'aTime_' + tIdx" disabled/>
              <label :for="'aTime_' + tIdx">{{tItem.time}}</label>
            </div>
          </div>
        </div>
        <div v-if="times.pTimes.length > 0"class="pm">
          <p>오후</p>
          <div class="chk_box">
            <div v-for="(tItem, tIdx) in times.pTimes">
              <input v-if="tItem.allow" type="radio" :id="'pTime_' + tIdx" :value="tItem.time" v-model="selTime"/>
              <input v-else type="radio" :id="'pTime_' + tIdx" disabled/>
              <label :for="'pTime_' + tIdx">{{tItem.time}}</label>
            </div>
          </div>
        </div>
      </div>
      <p class="sub_title">검진선택</p>
      <div class="chk_wrap">
        <div class="chk_box">
          <div v-for="(cItem, cIdx) in items">
            <input v-if="!cItem.active" type="checkbox" :id="'cItem_' + cIdx" :value="cIdx" v-model="selItems"/>
            <input v-else type="checkbox" :id="'cItem_' + cIdx" disabled/>
            <label :for="'cItem_' + cIdx">{{ cItem.id <= 51 ? cItem.name + '검진' : cItem.name}}</label>
          </div>
        </div>
      </div>
      <div class="btn_box">
        <button class="prev" @click="goTo(1)">이전</button>
        <button class="next" @click="goTo(3)" :disabled="!(selTime !== null && selItems.length > 0)">다음</button>
      </div>
    </div>
    <div v-else-if="step === 3" class="step3">
      <div class="input_box">
        <p>성함<span class="red">*</span></p>
        <input type="text" placeholder="성함을 입력해주세요." v-model="name"/>
      </div>
      <div class="input_box phone">
        <p>휴대전화번호 (-없이 입력)<span class="red">*</span></p>
        <div class="flex_box">
          <select class="select1" v-model="prefix_phone" :disabled="isSmsVeriComplete">
            <option value="010" selected>010</option>
          </select>
          <input type="text" placeholder="휴대폰번호를 입력해주세요." v-model="phone" :disabled="isSmsVeriComplete"/>
          <button :disabled="!validatePhone" @click="requestSmsVerifyCode()">{{ smsVeriOn ? '재발송' : (isSmsVeriComplete ? '인증완료' : '인증번호 받기')}}</button>
        </div>
      </div>
      <div v-show="!isSmsVeriComplete" class="input_box">
        <p>인증번호 입력</p>
        <div class="flex_box">
          <input type="text" pattern="\d*" placeholder="인증번호 4자리 입력" maxlength="6" v-model="code"/>
          <button :disabled="!validateCode" @click="validateSmsVerifyCode()">인증번호 확인</button>
          <p v-if="smsVeriOn" :class="{ timer: true, expire: time === 0 ? true : false }">{{prettyTime}}</p>
        </div>
        <p v-show="smsVeriMsg" class="dec">{{smsVeriMsg}}</p>
      </div>
      <div class="input_box">
        <p>생년월일<span class="red">*</span></p>
        <div class="bth">
          <select class="select2" v-model="brthYear">>
            <option value="" selected>선택</option>
            <option v-for="(year, idx) in years" :value="year">{{year}}</option>
          </select>
          <span class="bar">년</span>
          <select class="select3" @change="onChangeBrthMonth()" v-model="brthMonth">
            <option value="" selected>선택</option>
            <option value="01">01</option>
            <option value="02">02</option>
            <option value="03">03</option>
            <option value="04">04</option>
            <option value="05">05</option>
            <option value="06">06</option>
            <option value="07">07</option>
            <option value="08">08</option>
            <option value="09">09</option>
            <option value="10">10</option>
            <option value="11">11</option>
            <option value="12">12</option>
          </select>
          <span class="bar">월</span>
          <select class="select4" v-model="brthDay">
            <option value="" selected>선택</option>
            <option v-for="(day, idx) in days" :value="day">{{day}}</option>
          </select>
          <span class="bar">일</span>
        </div>
      </div>
      <div class="input_box">
        <p>성별<span class="red">*</span></p>
        <div class="gender">
          <button :class="{ active: isGenMaBtnActvie }" @click="setGender('1')">남자</button>
          <button :class="{ active: isGenFeBtnActive }" @click="setGender('2')">여자</button>
        </div>
      </div>
      <div class="chk_box">
        <input type="checkbox" id="agree" v-model="privacyChecked"/>
        <label for="agree" class="">개인정보 수집 및 이용약관 동의<span @click="onPrivacy">확인</span></label>
      </div>
      <div class="btn_box">
        <button class="prev" @click="goTo(2)">이전</button>
        <button class="next" @click="warningMakeAppointment" :disabled="!validateAppointment">예약접수</button>
      </div>
    </div>
    <div v-else class="step4">
      <div class="sec">
        <img src="@/assets/images/success.png" alt="" />
        <p>검진 예약접수가 완료되었습니다.</p>
        <span
        >검진병원에서 곧 <span class="green">예약확정 안내</span>가 진행될 예정입니다.<br />
                            (예약변경/취소는 모바일로 발송된 예약안내 메세지에서 가능)</span
        >
      </div>
      <div class="segment">
        <div class="flex_box">
          <div class="left">
            <p>예약번호</p>
          </div>
          <div class="right">
            <p>{{ appointmentNo }}</p>
          </div>
        </div>
        <div class="flex_box">
          <div class="left">
            <p>예약일시</p>
          </div>
          <div class="right">
            <p>{{ $moment(selDate).format('YYYY년 MM월 DD일(dd)') }} {{selTimeDis}}</p>
          </div>
        </div>
        <div class="flex_box">
          <div class="left">
            <p>예약병원</p>
          </div>
          <div class="right">
            <p>{{checkup.hmcNm}}</p>
            <p>{{checkup.hmcTelNo}}</p>
            <p class="gray">{{checkup.locAddr}}</p>
          </div>
        </div>
        <div class="flex_box">
          <div class="left">
            <p>검진항목</p>
          </div>
          <div class="right">
            <p>
              <template v-for="(idx) in selItems">{{items[idx].name}} {{ selItems.length -1 !== idx ? '검진, ' : '검진'}}</template>
            </p>
          </div>
        </div>
        <div class="flex_box">
          <div class="left">
            <p>예약자</p>
          </div>
          <div class="right">
            <div class="name_box">
              <p class="title">성명</p>
              <p>{{name}}</p>
            </div>
            <div class="name_box">
              <p class="title">생년월일</p>
              <p>{{ $moment(brthYear + brthMonth + brthDay).format('YYYY.MM.DD') }}</p>
            </div>
            <div class="name_box">
              <p class="title">성별</p>
              <p>{{ gender === '1' ? '남자' : '여자'}}</p>
            </div>
          </div>
        </div>
      </div>
      <button class="apply_btn" @click="close">확인</button>
    </div>
  </div>
</template>

<script>

  import { swiper, swiperSlide } from 'vue-awesome-swiper'
  import 'swiper/dist/css/swiper.css'

  export default {
    name: 'MakeAppointment',
    components: {
      swiper,
      swiperSlide,
    },
    props: {
      calendarHeads: Array,
      today: Date,
      baseDate: String,
      checkup: Object,
      channel: String,
    },
    data () {
      return {
        calendars : [],
        selDate: null,
        selTime: null,
        selItems: [],
        step: 1,
        times : null,
        items : [],
        years : [],
        days: [],

        name:'',
        prefix_phone: '010',
        phone: '',
        code: '',
        gender: '',
        brthYear: '',
        brthMonth: '',
        brthDay: '',

        time: 0,
        timer: null,
        smsVeriOn: false,
        smsVeriMsg:'',
        isSmsVeriComplete: false,
        privacyChecked: false,

        isGenMaBtnActvie: false,
        isGenFeBtnActive: false,

        appointmentNo: 0,

        stepOption : {
          spaceBetween: 0,
          centeredSlides: true,
          slideToClickedSlide: false,
          allowTouchMove: false,
          initialSlide: 0,
          /*
          navigation: {
            prevEl: '.swiper-button-prev_4',
            nextEl: '.swiper-button-next_4'
          }*/
        },

        calMonthOption : {
          spaceBetween: 10,
          initialSlide: 0,
          /*
          navigation: {
            prevEl: '.swiper-button-prev_4',
            nextEl: '.swiper-button-next_4'
          }*/
        },
        calDateOption :{
          spaceBetween: 0,
          initialSlide: 0,
          navigation: {
            prevEl: ".swiper-button-prev",
            nextEl: ".swiper-button-next",
          },
          centeredSlides: true,
          slidesPerView: 1,
          touchRatio: 0.2,
          slideToClickedSlide: true,
        },
      }
    },
    created: function () {

      // if (this.checkup.selDate === '') this.selDate = this.$moment(this.today).add(1, 'day').format('YYYYMMDD')
      // else this.selDate = this.checkup.selDate

      this.selDate = this.checkup.selDate
      this.selTime = this.checkup.appointmentTime.picked

      // this.checkup.active = false

      this.loadCalendars()

      var curYear = this.today.getFullYear()

      for (let i = curYear-85; i <= curYear; i++) {
        this.years.push(i)
      }

      /*
      for (let i = curYear-85; i < curYear - 16; i++) {
        this.years.push(i)
      }
      */
      //console.log(this.channel)


    },
    watch: {
      selTime (value) {
        //data -> msg 데이터가 변경될 때 실행

        if(this.items.length > 0) {
          //console.log('selTime: ' + value)
          this.getAppointmentItemExceed(this.selDate, this.selTime)
        }
      },
    },
    computed: {
      prettyTime: function ()  {
        let time = this.time / 60
        let minutes = parseInt(time)
        let secondes = Math.round((time - minutes) * 60)
        return minutes + ':' +  ('0' + secondes).slice(-2)

      },
      selTimeDis: function () {

        /*
        if(this.selTime !== null) {
          if(parseInt(this.selTime.substr(0,2)) < 12) return '오전 ' + this.selTime
          else return '오후 ' + this.selTime
        } else return null

        */
        if(this.selTime !== null) {
          let hour = parseInt(this.selTime.substr(0,2))

          if(hour < 12) return '오전 ' + this.selTime
          else return '오후 ' +  ('0' + (hour-12)).slice(-2) + ':' + this.selTime.substr(3,2)
        } else return null

      },
      selItemsDis: function () {

        var res = ''

        for (let i = 0; i < this.selItems.length; i++) {

          if(i == 0) res = '('
          else res += ' | '
          res += this.items[this.selItems[i]].name
        }

        if(res !== '') res += ')'

        return res
      },
      validatePhone() {
        if(this.isSmsVeriComplete) return false
        if(this.phone === '') return false;

        var temp = this.prefix_phone + this.phone.replace(new RegExp('-', 'g'), '');
        //temp = temp.replace(new RegExp('-', 'g'), '');
        // 총 핸드폰 자리수는 11글자이거나, 10자여야 합니다.
        if(temp.length==11 || temp.length==10) {
          var regExp = /^(01[016789]{1}|02|0[3-9]{1}[0-9]{1})([0-9]{3,4})([0-9]{4})$/;
          var re = new RegExp(regExp)
          return (re.test(temp))
        }else return false

      },

      validateCode() {
        if(this.isSmsVeriComplete) return false
        if(this.time === 0) return false;

        if(this.code.length === 4) return true

        return false

      },


      validateAppointment() {

        if(this.isSmsVeriComplete && this.privacyChecked && this.name !== '' && this.gender != '' && this.brthYear !== '' && this.brthMonth !== '' && this.brthDay !== '' ) return true

        return false

      },


    },
    mounted: function () {
      this.$nextTick(() => {

        const monthSlide = this.$refs.calMonthSlide
        const dateSlide = this.$refs.calDateSlide
        monthSlide.swiper.controller.control = dateSlide.swiper
        dateSlide.swiper.controller.control = monthSlide.swiper

        if(this.selTime !== null) {
          this.step = 2
          this.$refs.stepSlide.swiper.slideTo(1)
          this.getAppointmentTimes(this.selDate)
          this.getAppointmentItems()
          this.getAppointmentItemExceed(this.selDate, this.selTime)
        }

      })
    },
    methods: {

      setGender(val) {
        // console.log(val)
        this.gender = val
        if(val === '1') {
          this.isGenMaBtnActvie = true
          this.isGenFeBtnActive = false
        } else {
          this.isGenMaBtnActvie = false
          this.isGenFeBtnActive = true
        }
      },
      onChangeBrthMonth () {
        let max = 30
        this.days = []
        if (this.brthMonth == '02') max = 29
        else if(this.brthMonth == '01' || this.brthMonth == '03' || this.brthMonth == '05' || this.brthMonth == '07' || this.brthMonth == '08' || this.brthMonth == '10' || this.brthMonth == '12') max = 31

        for (let i = 1; i <= max; i++) {
          this.days.push(('0' + i).slice(-2))
        }

      },

      calDateSlideChange() {
        var currentSlide = this.$refs.calDateSlide.swiper.previousIndex
        var nextSlide = this.$refs.calDateSlide.swiper.realIndex

        // console.log(currentSlide + '-->' + nextSlide)


        if(this.calendars[nextSlide].days == null) this.loadAppointmentcalendars(nextSlide)


      },

      openMap (type) {
        const url = 'https://map.kakao.com/link/' + type + '/' + encodeURIComponent(this.checkup.hmcNm) + ',' + this.checkup.lat + ',' + this.checkup.lon
        window.open(url, '_blank')
      },

      onPrivacy () {
        this.$emit('privacy');
      },

      goTo (step) {
        this.step = step
        // console.log(step)
        this.$refs.stepSlide.swiper.slideTo(this.step-1)
        if(this.step === 2) {
          this.getAppointmentTimes(this.selDate)
          this.getAppointmentItems()
          if(this.selTime !== null) this.getAppointmentItemExceed(this.selDate, this.selTime)
        }
      },
      padLeft (n, width, z) {
        z = z || '0'
        n = n + ''
        return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n
      },
      close() {
        // console.log('colose')
        this.$emit('close');
      },

      loadCalendars () {

        this.calendars = []

        let calDate = this.$moment(this.today).add(1, 'day').toDate()

        let year = parseInt(this.baseDate.substr(0, 4))
        let month = parseInt(this.baseDate.substr(4, 2))

        // 해당 일정에 선택된 경우 해당 일정으로 초기 달력 페이지 설정
        if(this.selDate !== '') {
          year = parseInt(this.selDate.substr(0, 4))
          month = parseInt(this.selDate.substr(4, 2))
        }

        for (let i = 0; i < this.calendarHeads.length; i++) { // 캘린더 달력 생성

          let calYear = calDate.getFullYear()
          let calMonth = (calDate.getMonth() + 1);

          if(calYear == year && calMonth == month) {
            this.calMonthOption.initialSlide = i
            this.calDateOption.initialSlide = i
          }

          this.calendars.push({
            year: calYear,
            month: calMonth,
            days: null
          })

          if (calDate.getMonth() === 11) calDate = new Date(calYear + 1, 0, 1);
          else calDate = new Date(calYear, calMonth, 1);

        }
        // console.log('calen')
        // console.log(this.calendars)

        this.loadAppointmentcalendars(this.calMonthOption.initialSlide)
      },

      /* 월별 예약 달력 생성 */
      buildCalendar (appointmentCal, calDate) {
        const res = [[]]

        // console.log(item)

        var doMonth = new Date(calDate.getFullYear(), calDate.getMonth(), 1)
        var lastDate = new Date(calDate.getFullYear(), calDate.getMonth() + 1, 0)

        var nowday = this.$moment(this.today.getFullYear() + ('0' + (this.today.getMonth() + 1)).slice(-2) + ('0' + this.today.getDate()).slice(-2), 'YYYYMMDD')


        for(var i = 0; i < appointmentCal.days.length ; i++) {

          if(i !== 0 ) res.push([])

          var passedDay = 0

          for(var j = 0; j < appointmentCal.days[i].length; j++) {
            var day = appointmentCal.days[i][j];
            var diffday = -100
            var radius = ''


            if(day.day !== 0) {
              // console.log(item.appointmentCal.year + ('0' + item.appointmentCal.month).slice(-2) + ('0' + item.appointmentCal.day).slice(-2))
              var date = appointmentCal.year + ('0' + appointmentCal.month).slice(-2) + ('0' + day.day).slice(-2)
              var calday = this.$moment(date, 'YYYYMMDD')
              diffday = calday.diff(nowday, 'days')

              if(diffday <= 0) {
                if(passedDay === 0) {
                  if(j === appointmentCal.days[i].length-1) radius = 'border-radius: 5px;'
                  else if(diffday === 0) radius = 'border-radius: 5px;'
                  else radius = 'border-radius: 5px 0px 0px 5px;'
                } else {
                  if(j === appointmentCal.days[i].length-1 || diffday === 0) radius = 'border-radius: 0px 5px 5px 0px;'
                }
              }
              passedDay++

            }
            res[i].push({ day: day.day, allow: day.allow, diffDay: diffday, radius : radius, date: date })
          }
        }

        while (res[res.length - 1].length < 7) {
          res[res.length - 1].push({ day: 0, allow: false, diffDay: -100, radius : radius, date: null })
        }

        // console.log(res)

        return res
      },

      setDate(date) {
        this.selDate = date
        this.selTime = null
      },

      startTimer(sec) {

        if (this.timer) {
          clearInterval(this.timer)
          this.timer = null
        }

        this.time = sec

        this.timer = setInterval( () => {
          if (this.time > 0) {
            this.time--
          } else {
            clearInterval(this.timer)
            this.timer = null
          }
        }, 1000 )

      },

      stopTimer() {
        if (this.timer) {
          clearInterval(this.timer)
          this.timer = null
        }
        this.smsVeriOn = false
        this.time = 0

      },

      loadAppointmentcalendars(idx) {

        const self = this

        let calDate = new Date(this.calendars[idx].year, this.calendars[idx].month -1, 1)

        this.$http.get(this.$apiUrl + 'checkups/' + this.checkup.id + '/appointment/schedules/' + this.calendars[idx].year + '/' + ('0' + this.calendars[idx].month).slice(-2))
          .then(response => {

            self.calendars[idx].days = self.buildCalendar(response.data, calDate)
          })
          .catch(error => {
            self.calendars[idx].days = [[]]
            console.log(error)
          })
      },

      requestSmsVerifyCode() {

        this.code = ''
        this.smsVeriOn = true
        this.smsVeriMsg = ''

        this.startTimer(180)


        const self = this

        self.$emit('alert', { code : 1, text : '인증번호가 발송되었습니다.'});


        this.$http.get(this.$apiUrl + 'base/sms/certification/' + (this.prefix_phone + this.phone.replace(new RegExp('-', 'g'), '') )+ '/send')
          .then(response => {
            self.$emit('alert', { code : 1, text : '인증번호가 발송되었습니다.'});

          })
          .catch(error => {

            console.log(error)
          })

      },

      validateSmsVerifyCode() {
        const self = this

        this.$http.get(this.$apiUrl + 'base/sms/certification/' + (this.prefix_phone + this.phone.replace(new RegExp('-', 'g'), '') )+ '/verify/'+ this.code)
          .then(response => {
            self.stopTimer()
            self.isSmsVeriComplete = true

          })
          .catch(error => {
            if (error.response) {
              self.smsVeriMsg = error.response.data.error_description
              // self.stopTimer()
              // self.isSmsVeriComplete = true

            }
          })
      },

      warningMakeAppointment() {
        this.$emit('alert', { code : 3, text : '예약접수 완료후 검진병원에서 곧 예약확정을 해드릴 예정입니다.<br>조금만 기달려 주세요~' });
      },
      createAppintment() {

        /*
        if(this.name === '') {
          this.$emit('alert', { code : 1, text : '예약자 성함을 입력해 주세요.'});
          return
        }

        if(!this.smsVeriOn) {
          this.$emit('alert', { code : 1, text : '휴대폰 번호를 입력하고 인증요청 버튼을 눌러주세요.'});
          return
        }

        if(!this.isSmsVeriComplete) {
          this.$emit('alert', { code : 1, text : '휴대폰으로 전송된 코드를 입력하여 인증을 완료하세요.'});
          return
        }

        if(this.brthYear === '' || this.brthMonth === '' || this.brthDay === '') {
          this.$emit('alert', { code : 1, text : '생년월일을 선택해주세요.'});
          return
        }

        if(this.gender === '') {
          this.$emit('alert', { code : 1, text : '성별을 선택해 주세요.'});
          return
        }

        if(!this.privacyChecked) {
          this.$emit('alert', { code : 1, text : '약관 동의에 체크해 주세요.'});
          return
        }
        */
        const self = this

        let hospitalCheckupList = []

        for (let i = 0; i < this.selItems.length; i++) {
          hospitalCheckupList.push({ 'id' : this.items[this.selItems[i]].id })
        }

        let payload = {
          patientNm : this.name,
          brthDate : this.brthYear + this.brthMonth + this.brthDay,
          gen : this.gender,
          phone: this.prefix_phone + this.phone.replace(new RegExp('-', 'g'), ''),
          date : this.selDate,
          time : this.selTime,
          channel: '1',
          hospitalCheckupList : hospitalCheckupList
        }

        this.$http.post(this.$apiUrl + 'checkups/' + this.checkup.id + '/appointments?ch=' + this.channel, payload)
          .then(response => {
            //response.data.id
            self.appointmentNo = response.data.id
            self.goTo(4)
            // console.log(response.data)



            //result = { appointmentNo : response.data.id, payload : payload }

          })
          .catch(error => {
            self.$emit('alert', { code : 2, text : error.response.data.error_description});
            console.log(error.response.data)
          })


        // console.log(payload)

      },

      getAppointmentItems() {

        const self = this

        this.$http.get(this.$apiUrl + 'checkups/' + this.checkup.id + '/appointment/items')
          .then(response => {
            this.items = response.data
          })
          .catch(error => {
            this.times = []
            console.log(error)
          })
      },

      getAppointmentTimes(date) {

        const self = this

        this.$http.get(this.$apiUrl + 'checkups/' + this.checkup.id + '/appointment/schedules/' + date.substr(0, 4) + '/' + date.substr(4, 2) + '/' + date.substr(6, 2))
          .then(response => {
            this.times = self.appointmentTimeTables(response.data)
          })
          .catch(error => {
            this.times = undefined
            console.log(error)
          })
      },

      getAppointmentItemExceed(date, time) {

        const self = this

        this.selItems = []

        this.$http.get(this.$apiUrl + 'checkups/' + this.checkup.id + '/appointment/itemExceed/' + date + '/' + time.replace(':',''))
          .then(response => {

            //console.log(response.data)

            let exceedMap = new Map();

            for (let i = 0; i < response.data.length; i++) {
              exceedMap.set(response.data[i].item_id, true);
            }
            for (let i = 0; i < this.items.length; i++) {
              if(exceedMap.get(this.items[i].id) !== true) this.items[i].active = false
              else this.items[i].active = true
            }
          })
          .catch(error => {
            console.log(error)
          })
      },

      appointmentTimeTables(appointmentTime) {

        if(appointmentTime == undefined) return appointmentTime

        const res = { date : appointmentTime.date, nextDate : appointmentTime.nextDate, aTimes : [] , pTimes :[]}

        for(let i = 0; i < appointmentTime.times.length; i++) {

          if(parseInt(appointmentTime.times[i].time.substr(0,2)) < 12) {
            res.aTimes.push(appointmentTime.times[i])
          } else {
            res.pTimes.push(appointmentTime.times[i])
          }
        }
        return res
      },

    },

  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>

<template>
  <div class="inner">
    <div class="top_menu" :style="(step === 0 || step === 3) ? 'height:40px;' : ''">
      <div class="popup_title">
        <swiper
          ref="stepSlide"
          :options="stepOption"
        >
          <!--@slide-change="mCalHeadSlideChange"-->
          <swiper-slide>
            <h5 style="border-bottom: none;"></h5>
          </swiper-slide>
          <swiper-slide>
            <h5>희망 검진일 선택</h5>
          </swiper-slide>
          <swiper-slide>
            <h5>희망 시간 및 검진종류 선택</h5>
          </swiper-slide>
          <swiper-slide>
            <h5 style="border-bottom: none;"></h5>
          </swiper-slide>
        </swiper>
        <button class="popup_close" @click="close">x</button>
      </div>
      <div v-if="(step !== 0 && step !== 3)" class="title">
        <div class="flex_box">
          <div class="company_img">
            <div v-if="appointment.checkup.active && appointment.checkup.hmcTpImg" class="img img1" :style="appointment.checkup.hmcTpImg"></div>
            <div v-else :class="appointment.checkup.exmdChrg.aImgCls">
              <p v-html="appointment.checkup.exmdChrg.aImgHtml"></p>
            </div>
          </div>
          <div class="company_desc">
            <div class="overflow">
              <h3>
                <p>{{appointment.checkup.hmcNm}}</p>
                <span class="gray">({{appointment.checkup.hmcTelNo}})</span>
              </h3>
              <div class="icon_box">
                <a href="#" class="blog_off"></a>
                <a href="#" class="cafe_off"></a>
                <a href="#" class="insta_off"></a>
                <a href="#" class="kakao_off"></a>
              </div>
            </div>
            <p>
              <span class="yellow">{{appointment.checkup.exmdChrg.str}}</span>
            </p>
            <div class="address">
              <a v-if="appointment.checkup.lon != 0 && appointment.checkup.lat != 0" href="#0" @click.prevent="openMap('map')" class="gray">{{appointment.checkup.locAddr}}
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 18" class="addr-tool-ico" role="presentation"><path d="M15 6.97a6.92 6.92 0 0 1-1.12 3.77l-5.51 7.08a.47.47 0 0 1-.74 0L2.1 10.71A6.93 6.93 0 0 1 1 6.97 7 7 0 0 1 8 0v.93V0a7 7 0 0 1 7 6.97zm-13 0c0 1.15.4 2.3.99 3.24L8 16.7l5.04-6.5A5.95 5.95 0 0 0 8 1C4.66 1 2 3.64 2 6.97zm6-1.54A1.58 1.58 0 0 0 8 8.6a1.57 1.57 0 0 0 0-3.16zm0-.93a2.51 2.51 0 0 1 0 5.02A2.51 2.51 0 1 1 8 4.5z"></path></svg>
              </a>
              <a v-else href="#" class="gray">{{appointment.checkup.locAddr}}</a>
            </div>
          </div>
        </div>
        <div class="date">
          <p>{{selDate !== '' ? $moment(selDate).format('YYYY.MM.DD(dd)') : '날짜를 선택하세요.'}}<span v-show="step > 1">{{selTimeDis}}</span></p>
          <span v-show="step > 1"> {{selItemsDis}}</span>
        </div>
      </div>
    </div>
    <div v-if="step === 0" class="step4">
      <div class="sec">
        <template v-if="appointment.checkup.active === false || appointment.checkup.appointment === false">
          <img src="@/assets/images/exclamation.png" alt="" />
          <p>검진예약 정보 안내</p>
          <div class="exclamBox">
            {{appointment.hmcNm}}은
            <p style="margin-top:7px;font-weight: bold;" v-if="appointment.checkup.appointment === false && appointment.checkup.appointmentSetDate != undefined">‘{{$moment(appointment.checkup.appointmentSetDate).format('YYYY.MM.DD') }} 이후로 우리동네 국가검진<br>예약 서비스를 제공하고 있지 않습니다.’</p>
            <p style="margin-top:7px;font-weight: bold;" v-else>‘{{$moment(appointment.checkup.expirationDate).add(8, 'day').format('YYYY.MM.DD') }} 이후로 우리동네 국가검진<br>예약 서비스를 제공하고 있지 않습니다.’</p>
            <br>
            <p>검진 예약 및 관련 문의는<br><a :href="'tel:'+ appointment.checkup.hmcTelNo">☎ {{appointment.checkup.hmcTelNo}}</a><br>로 직접 연락 부탁드립니다.</p>
          </div>
        </template>
        <template v-else>
          <img src="@/assets/images/success.png" alt="" />
          <template v-if="appointmentStatus === 1">
            <p>검진예약 접수 내역</p>
            <span>검진병원에서 곧 <span class="green">예약확정 안내</span>가 진행될 예정입니다.</span>
          </template>
          <template v-else-if="appointmentStatus === 2">
            <p>검진예약 확정 내역</p>
          </template>
          <template v-else-if="appointmentStatus === 3">
            <p>예약취소 접수 내역</p>
            <span>검진병원에서 곧 <span class="green">취소확정 안내</span>가 진행될 예정입니다.</span>
          </template>
          <template v-else>
            <p>검진예약 취소 내역</p>
          </template>
        </template>

      </div>

      <div class="segment">
        <p v-if="appointmentStatus === 1" class="sub_dec">검진예약 : <b>요청일({{ $moment(appointmentRequestAt).format('YYYY.MM.DD') }})</b></p>
        <p v-else-if="appointmentStatus === 2" class="sub_dec">검진예약 : 요청일({{ $moment(appointmentRequestAt).format('YY.MM.DD') }})→<b>확정일({{ $moment(appointment.confirmedAt).format('YY.MM.DD') }})</b></p>
        <p v-else-if="appointmentStatus === 3" class="sub_dec">예약취소 : <b>요청일({{ $moment(appointmentRequestAt).format('YYYY.MM.DD') }})</b></p>
        <p v-else class="sub_dec">예약취소 : 요청일({{ $moment(appointmentRequestAt).format('YY.MM.DD') }})→<b>취소일({{ $moment(appointment.confirmedAt).format('YY.MM.DD') }})</b></p>
        <div class="flex_box">
          <div class="left">
            <p>예약번호</p>
          </div>
          <div class="right">
            <p>{{ appointment.appointmentNo }}</p>
          </div>
        </div>
        <div class="flex_box">
          <div class="left">
            <p>예약일시</p>
          </div>
          <div class="right">
            <p>{{ $moment(appointmentDate).format('YYYY년 MM월 DD일(dd)') }} {{appointmentTimeDis}}</p>
          </div>
        </div>
        <div class="flex_box">
          <div class="left">
            <p>예약병원</p>
          </div>
          <div class="right">
            <p>{{appointment.checkup.hmcNm}}</p>
            <p>{{appointment.checkup.hmcTelNo}}</p>
            <p class="gray">{{appointment.checkup.locAddr}}</p>
          </div>
        </div>
        <div class="flex_box">
          <div class="left">
            <p>검진항목</p>
          </div>
          <div class="right">
            <p>{{appointmentTitle}}</p>
          </div>
        </div>
        <div class="flex_box">
          <div class="left">
            <p>예약자</p>
          </div>
          <div class="right">
            <div class="name_box">
              <p class="title">성명</p>
              <p>{{appointment.patientNm}}</p>
            </div>
            <div class="name_box">
              <p class="title">생년월일</p>
              <p>{{ $moment(appointment.brthDate).format('YYYY.MM.DD') }}</p>
            </div>
            <div class="name_box">
              <p class="title">성별</p>
              <p>{{ appointment.gen === '1' ? '남자' : '여자'}}</p>
            </div>
          </div>
        </div>
      </div>
      <template v-if="appointment.checkup.active === false || appointment.checkup.appointment === false">
        <button class="apply_btn" @click="close">확인</button>
      </template>
      <template v-else-if="appointmentStatus > 2">
        <button class="apply_btn" @click="close">확인</button>
      </template>
      <template v-else>
        <div class="btn_box">
          <button class="change_btn" @click="goToChange">예약변경</button>
          <button class="cancel_btn" @click="cancelAppointmentOpen">예약취소</button>
        </div>
      </template>
    </div>
    <div v-else-if="step === 1" class="step1">
      <div class="calendar">
        <swiper
          class="cal_month"
          ref="calMonthSlide"
          :options="calMonthOption"
        >
          <swiper-slide v-for="(head) in calendarHeads" >
            {{head.year + '. ' + padLeft(String(head.month),2)}}
          </swiper-slide>
          <div class="swiper_btn_box swiper-button-prev" slot="button-prev"><font-awesome-icon icon="fa-solid fa-chevron-left" /></div>
          <div class="swiper_btn_box swiper-button-next" slot="button-next"><font-awesome-icon icon="fa-solid fa-chevron-right" /></div>
        </swiper>
        <div class="week">
          <p>일</p>
          <p>월</p>
          <p>화</p>
          <p>수</p>
          <p>목</p>
          <p>금</p>
          <p>토</p>
        </div>
        <swiper
          class="cal_date"
          ref="calDateSlide"
          :options="calDateOption"
          @slideChange="calDateSlideChange"
        >
          <swiper-slide v-for="(calendar, calIdx) in calendars" >
            <div class="date_box">
              <div v-for="(week, wIdx) in calendar.days" class="date">
                <template v-for="(day, dIdx) in week">
                  <button v-if="day.day === 0" type="button" class="blank"></button>
                  <button v-else-if="day.diffDay <= 0" type="button" class="last_week"><p :style="day.radius">{{day.day}}</p><span v-if="day.diffDay === 0">오늘</span></button>
                  <button v-else-if="day.allow === true" :class="day.diffDay === 1 ? (selDate === day.date ? 'tomorrow active' : 'tomorrow'): (selDate === day.date ? 'active' : '')" type="button" @click="setDate(day.date)"><p>{{day.day}}</p><span v-if="day.diffDay === 1">내일</span></button>
                  <button v-else type="button" :class="day.diffDay === 1 ? 'tomorrow' : ''" disabled><p>{{day.day}}</p><span v-if="day.diffDay === 1">내일</span></button>
                </template>
              </div>
            </div>
          </swiper-slide>
        </swiper>
        <div class="calendar_desc">
          <p><span></span>선택</p>
          <p><span></span>불가</p>
        </div>
      </div>

      <div class="btn_box">
        <button class="next" :disabled="selDate === ''" @click="goTo(2)">다음</button>
      </div>
    </div>
    <div v-else-if="step === 2" class="step2">

      <div v-if="times && (times.aTimes.length > 0 || times.pTimes.length > 0)" class="time">
        <div v-if="times.aTimes.length > 0"class="am">
          <p>오전</p>
          <div class="chk_box">
            <div v-for="(tItem, tIdx) in times.aTimes">
              <input v-if="tItem.allow" type="radio" :id="'aTime_' + tIdx" :value="tItem.time" v-model="selTime"/>
              <input v-else type="radio" :id="'aTime_' + tIdx" disabled/>
              <label :for="'aTime_' + tIdx">{{tItem.time}}</label>
            </div>
          </div>
        </div>
        <div v-if="times.pTimes.length > 0"class="pm">
          <p>오후</p>
          <div class="chk_box">
            <div v-for="(tItem, tIdx) in times.pTimes">
              <input v-if="tItem.allow" type="radio" :id="'pTime_' + tIdx" :value="tItem.time" v-model="selTime"/>
              <input v-else type="radio" :id="'pTime_' + tIdx" disabled/>
              <label :for="'pTime_' + tIdx">{{tItem.time}}</label>
            </div>
          </div>
        </div>
      </div>
      <p class="sub_title">검진선택</p>
      <div class="chk_wrap">
        <div class="chk_box">
          <div v-for="(cItem, cIdx) in items">
            <input v-if="!cItem.active" type="checkbox" :id="'cItem_' + cIdx" :value="cIdx" v-model="selItems"/>
            <input v-else type="checkbox" :id="'cItem_' + cIdx" disabled/>
            <label :for="'cItem_' + cIdx">{{ cItem.id <= 51 ? cItem.name + '검진' : cItem.name}}</label>
          </div>
        </div>
      </div>
      <div class="btn_box">
        <button class="prev" @click="goTo(1)">이전</button>
        <button class="next" @click="changeAppointmet" :disabled="!validateAppointment">변경하기</button>
      </div>
    </div>
    <div v-if="step === 3" class="step4">
      <div class="sec">
        <img src="@/assets/images/success.png" alt="" />
        <template v-if="appointmentStatus === 1">
          <p>검진예약변경이 완료 되었습니다.</p>
          <span>검진병원에서 곧 <span class="green">예약확정 안내</span>가 진행될 예정입니다.</span>
        </template>
        <template v-else-if="appointmentStatus === 3">
          <p>예약취소 접수 내역</p>
          <span>검진병원에서 곧 <span class="green">취소확정 안내</span>가 진행될 예정입니다.</span>
        </template>
        <template v-else>
          <p>검진예약 취소 내역</p>
        </template>
      </div>

      <div class="segment">
        <p v-if="appointmentStatus === 1" class="sub_dec">검진예약 : <b>접수일({{ $moment(appointmentRequestAt).format('YYYY.MM.DD')}})</b></p>
        <p v-else-if="appointmentStatus === 3" class="sub_dec">예약취소 : <b>접수일({{ $moment(today).format('YYYY.MM.DD') }})</b></p>
        <p v-else class="sub_dec">예약취소 : 접수일({{ $moment(today).format('YY.MM.DD') }})→<b>취소일({{ $moment(today).format('YY.MM.DD') }})</b></p>
        <div class="flex_box">
          <div class="left">
            <p>예약번호</p>
          </div>
          <div class="right">
            <p>{{ appointment.appointmentNo }}</p>
          </div>
        </div>
        <div class="flex_box">
          <div class="left">
            <p>예약일시</p>
          </div>
          <div class="right">
            <p>{{ $moment(appointmentDate).format('YYYY년 MM월 DD일(dd)') }} {{appointmentTimeDis}}</p>
          </div>
        </div>
        <div class="flex_box">
          <div class="left">
            <p>예약병원</p>
          </div>
          <div class="right">
            <p>{{appointment.checkup.hmcNm}}</p>
            <p>{{appointment.checkup.hmcTelNo}}</p>
            <p class="gray">{{appointment.checkup.locAddr}}</p>
          </div>
        </div>
        <div class="flex_box">
          <div class="left">
            <p>검진항목</p>
          </div>
          <div class="right">
            <p>{{appointmentTitle}}</p>
          </div>
        </div>
        <div class="flex_box">
          <div class="left">
            <p>예약자</p>
          </div>
          <div class="right">
            <div class="name_box">
              <p class="title">성명</p>
              <p>{{appointment.patientNm}}</p>
            </div>
            <div class="name_box">
              <p class="title">생년월일</p>
              <p>{{ $moment(appointment.brthDate).format('YYYY.MM.DD') }}</p>
            </div>
            <div class="name_box">
              <p class="title">성별</p>
              <p>{{ appointment.gen === '1' ? '남자' : '여자'}}</p>
            </div>
          </div>
        </div>
      </div>
      <button class="apply_btn" @click="close">확인</button>
    </div>



  </div>
</template>

<script>

  import { swiper, swiperSlide } from 'vue-awesome-swiper'
  import 'swiper/dist/css/swiper.css'

  export default {
    name: 'ViewAppointment',
    components: {
      swiper,
      swiperSlide,
    },
    props: {
      calendarHeads: Array,
      today: Date,
      appointment: Object,
    },
    data () {
      return {
        calendars : [],
        selDate: null,
        selTime: null,
        selItems: [],
        step: 0,
        times : null,
        items : [],
        years : [],
        days: [],

        appointmentStatus: 1 , // 1 : 예약신청, 2 : 예약확정, 3 : 취소신청, 4 : 취소확정
        appointmentRequestAt: '', // 요청일시
        appointmentDate : '',
        appointmentTime : '',
        appointmentTitle : '',


        name:'',
        prefix_phone: '010',
        phone: '',

        stepOption : {
          spaceBetween: 0,
          centeredSlides: true,
          slideToClickedSlide: false,
          allowTouchMove: false,
          initialSlide: 0,
          /*
          navigation: {
            prevEl: '.swiper-button-prev_4',
            nextEl: '.swiper-button-next_4'
          }*/
        },

        calMonthOption : {
          spaceBetween: 10,
          initialSlide: 0,
          /*
          navigation: {
            prevEl: '.swiper-button-prev_4',
            nextEl: '.swiper-button-next_4'
          }*/
        },
        calDateOption :{
          spaceBetween: 0,
          initialSlide: 0,
          navigation: {
            prevEl: ".swiper-button-prev",
            nextEl: ".swiper-button-next",
          },
          centeredSlides: true,
          slidesPerView: 1,
          touchRatio: 0.2,
          slideToClickedSlide: true,
        }
      }
    },
    created: function () {

      //console.log(this.appointment)

      // if (this.checkup.selDate === '') this.selDate = this.$moment(this.today).add(1, 'day').format('YYYYMMDD')
      // else this.selDate = this.checkup.selDate

      this.selDate = this.$moment(this.today).add(1, 'day').format('YYYYMMDD')
      // this.selTime = this.appointment.time

      // console.log(this.selDate)
      // this.checkup.active = false

      this.loadCalendars()

      // 1 : 예약신청, 2 : 예약확정, 3 : 취소신청, 4 : 취소확정

      if(this.appointment.appointmentRequest !== undefined) {

        this.appointmentRequestAt = this.appointment.appointmentRequest.createdAt

        if(this.appointment.appointmentRequest.type === '1') {
          this.appointmentStatus = 1
          this.appointmentDate = this.appointment.appointmentRequest.date
          this.appointmentTime = this.appointment.appointmentRequest.time
          this.appointmentTitle = this.getTitleStr(this.appointment.appointmentRequest.title)
        }
        else {
          this.appointmentStatus = 3
          this.appointmentDate = this.appointment.date
          this.appointmentTime = this.appointment.time
          this.appointmentTitle = this.getTitleStr(this.appointment.title)

        }

      } else {

        this.appointmentRequestAt = this.appointment.updatedAt
        this.appointmentDate = this.appointment.date
        this.appointmentTime = this.appointment.time
        this.appointmentTitle = this.getTitleStr(this.appointment.title)

        if(this.appointment.status === '1') this.appointmentStatus = 1
        else if(this.appointment.status === '2') this.appointmentStatus = 2
        else if(this.appointment.status === '3') this.appointmentStatus = 4

      }



    },

    watch: {
      selTime (value) {
        //data -> msg 데이터가 변경될 때 실행

        if(this.items.length > 0) {
          //console.log('selTime: ' + value)
          this.getAppointmentItemExceed(this.selDate, this.selTime)
        }
      },
    },

    computed: {
      prettyTime: function ()  {
        let time = this.time / 60
        let minutes = parseInt(time)
        let secondes = Math.round((time - minutes) * 60)
        return minutes + ':' +  ('0' + secondes).slice(-2)

      },

      appointmentTimeDis: function () {

        let hour = parseInt(this.appointmentTime.substr(0,2))

        if(hour < 12) return '오전 ' + this.appointmentTime
        else return '오후 ' + ('0' + (hour-12)).slice(-2) + ':' + this.appointmentTime.substr(3,2)

      },

      selTimeDis: function () {

        if(this.selTime !== null) {
          let hour = parseInt(this.selTime.substr(0,2))

          if(hour < 12) return '오전 ' + this.selTime
          else return '오후 ' +  ('0' + (hour-12)).slice(-2) + ':' + this.selTime.substr(3,2)
        } else return null
      },

      selItemsDis: function () {

        var res = ''

        for (let i = 0; i < this.selItems.length; i++) {

          if(i == 0) res = '('
          else res += ' | '
          res += this.items[this.selItems[i]].name
        }

        if(res !== '') res += ')'

        return res
      },


      validateAppointment() {

        if(this.selTime !== null && this.selItems.length > 0) return true

        return false

      },


    },
    mounted: function () {

    },
    methods: {

      getTitleStr(title) {
        let res = ''

        let titles = title.split(',')

        for (let i =0; i < titles.length; i++) {
          if(res !== '') res += ','
          res += titles[i] + '검진'
        }
        return res
      },

      getSelItemTitleStr() {

        var res = ''

        for (let i = 0; i < this.selItems.length; i++) {

          if(res !== '') res += ','

          res += this.items[this.selItems[i]].name + '검진'
        }
        return res
      },
      onChangeBrthMonth () {
        let max = 30
        this.days = []
        if (this.brthMonth == '02') max = 29
        else if(this.brthMonth == '01' || this.brthMonth == '03' || this.brthMonth == '05' || this.brthMonth == '07' || this.brthMonth == '08' || this.brthMonth == '10' || this.brthMonth == '12') max = 31

        for (let i = 1; i <= max; i++) {
          this.days.push(('0' + i).slice(-2))
        }

      },

      calDateSlideChange() {
        var currentSlide = this.$refs.calDateSlide.swiper.previousIndex
        var nextSlide = this.$refs.calDateSlide.swiper.realIndex

        // console.log(currentSlide + '-->' + nextSlide)


        if(this.calendars[nextSlide].days == null) this.loadAppointmentcalendars(nextSlide)


      },

      openMap (type) {
        const url = 'https://map.kakao.com/link/' + type + '/' + encodeURIComponent(this.checkup.hmcNm) + ',' + this.checkup.lat + ',' + this.checkup.lon
        window.open(url, '_blank')
      },

      goToChange () {
        this.step = 1
        // console.log(step)
        this.$refs.stepSlide.swiper.slideTo(1, 0, false)

        this.$nextTick(() => {

          const monthSlide = this.$refs.calMonthSlide
          const dateSlide = this.$refs.calDateSlide
          monthSlide.swiper.controller.control = dateSlide.swiper
          dateSlide.swiper.controller.control = monthSlide.swiper

        })

      },

      goTo (step) {
        this.step = step
        // console.log(step)
        this.$refs.stepSlide.swiper.slideTo(this.step)
        if(this.step === 2) {
          this.getAppointmentTimes(this.selDate)
          this.getAppointmentItems()
          if(this.selTime !== null) this.getAppointmentItemExceed(this.selDate, this.selTime)
        }
      },
      padLeft (n, width, z) {
        z = z || '0'
        n = n + ''
        return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n
      },
      close() {
        // console.log('colose')
        this.$emit('close');
      },

      loadCalendars () {

        this.calendars = []

        let calDate = this.$moment(this.today).add(1, 'day').toDate()

        let year = parseInt(this.appointment.date.substr(0, 4))
        let month = parseInt(this.appointment.date.substr(4, 2))

        // 해당 일정에 선택된 경우 해당 일정으로 초기 달력 페이지 설정
        if(this.selDate !== '') {
          year = parseInt(this.selDate.substr(0, 4))
          month = parseInt(this.selDate.substr(4, 2))
        }

        for (let i = 0; i < this.calendarHeads.length; i++) { // 캘린더 달력 생성

          let calYear = calDate.getFullYear()
          let calMonth = (calDate.getMonth() + 1);

          if(calYear == year && calMonth == month) {
            this.calMonthOption.initialSlide = i
            this.calDateOption.initialSlide = i
          }

          this.calendars.push({
            year: calYear,
            month: calMonth,
            days: null
          })

          if (calDate.getMonth() === 11) calDate = new Date(calYear + 1, 0, 1);
          else calDate = new Date(calYear, calMonth, 1);

        }
        // console.log('calen')
        // console.log(this.calendars)

        //console.log(this.calMonthOption.initialSlide)

        this.loadAppointmentcalendars(this.calMonthOption.initialSlide)
      },

      /* 월별 예약 달력 생성 */
      buildCalendar (appointmentCal, calDate) {
        const res = [[]]

        // console.log(item)

        var doMonth = new Date(calDate.getFullYear(), calDate.getMonth(), 1)
        var lastDate = new Date(calDate.getFullYear(), calDate.getMonth() + 1, 0)

        var nowday = this.$moment(this.today.getFullYear() + ('0' + (this.today.getMonth() + 1)).slice(-2) + ('0' + this.today.getDate()).slice(-2), 'YYYYMMDD')


        for(var i = 0; i < appointmentCal.days.length ; i++) {

          if(i !== 0 ) res.push([])

          var passedDay = 0

          for(var j = 0; j < appointmentCal.days[i].length; j++) {
            var day = appointmentCal.days[i][j];
            var diffday = -100
            var radius = ''


            if(day.day !== 0) {
              // console.log(item.appointmentCal.year + ('0' + item.appointmentCal.month).slice(-2) + ('0' + item.appointmentCal.day).slice(-2))
              var date = appointmentCal.year + ('0' + appointmentCal.month).slice(-2) + ('0' + day.day).slice(-2)
              var calday = this.$moment(date, 'YYYYMMDD')
              diffday = calday.diff(nowday, 'days')

              if(diffday <= 0) {
                if(passedDay === 0) {
                  if(j === appointmentCal.days[i].length-1) radius = 'border-radius: 5px;'
                  else if(diffday === 0) radius = 'border-radius: 5px;'
                  else radius = 'border-radius: 5px 0px 0px 5px;'
                } else {
                  if(j === appointmentCal.days[i].length-1 || diffday === 0) radius = 'border-radius: 0px 5px 5px 0px;'
                }
              }
              passedDay++

            }
            res[i].push({ day: day.day, allow: day.allow, diffDay: diffday, radius : radius, date: date })
          }
        }

        while (res[res.length - 1].length < 7) {
          res[res.length - 1].push({ day: 0, allow: false, diffDay: -100, radius : radius, date: null })
        }

        // console.log(res)

        return res
      },

      setDate(date) {
        this.selDate = date
        this.selTime = null
      },


      loadAppointmentcalendars(idx) {

        const self = this

        let calDate = new Date(this.calendars[idx].year, this.calendars[idx].month -1, 1)

        this.$http.get(this.$apiUrl + 'checkups/' + this.appointment.checkup.id + '/appointment/schedules/' + this.calendars[idx].year + '/' + ('0' + this.calendars[idx].month).slice(-2))
          .then(response => {

            self.calendars[idx].days = self.buildCalendar(response.data, calDate)

            //console.log(self.calendars[idx].days)
          })
          .catch(error => {
            self.calendars[idx].days = [[]]
            console.log(error)
          })
      },


      cancelAppointmentOpen() {

        let timeStr = ''

        if(parseInt(this.appointmentTime.substr(0,2)) < 12) timeStr = ' 오전 ' + this.appointmentTime
        else timeStr = ' 오후 ' + this.appointmentTime


        this.$emit('cancel', this.$moment(this.appointmentDate).format('YYYY년 MM월 DD일(dd)') + timeStr );
      },

      cancelAppointment() {



        const self = this

        this.$http.delete(this.$apiUrl + 'appointments/' + this.appointment.appointmentNo + '/' + this.appointment.phone)
          .then(response => {

            //console.log(response.data)

            if(response.data.updatedAt !== null) self.appointmentStatus = 4
            else self.appointmentStatus = 3

            self.goTo(3)

          })
          .catch(error => {
            self.$emit('alert', { code : 2, text : error.response.data.error_description});
            console.log(error.response.data)
          })


      },

      changeAppointmet() {

        const self = this

        if(this.appointmentTitle === this.getSelItemTitleStr() && this.appointmentTime === this.selTime && this.appointmentDate === this.selDate) {
          self.$emit('alert', { code : 1, text : '기존 예약정보와 동일합니다.<br>변경내용을 확인해주세요.'});
          return
        }


        let hospitalCheckupList = []

        for (let i = 0; i < this.selItems.length; i++) {
          hospitalCheckupList.push({ 'id' : this.items[this.selItems[i]].id })
        }

        let payload = {
          date : this.selDate,
          time : this.selTime,
          hospitalCheckupList : hospitalCheckupList
        }

        this.$http.put(this.$apiUrl + 'appointments/' + this.appointment.appointmentNo + '/' + this.appointment.phone , payload)
          .then(response => {
            //response.data.id
            self.appointmentNo = response.data.id
            // console.log(response.data)

            self.appointmentStatus = 1
            self.appointmentDate = payload.date
            self.appointmentTime = payload.time
            self.appointmentTitle = self.getSelItemTitleStr()
            self.goTo(3)


            //result = { appointmentNo : response.data.id, payload : payload }

          })
          .catch(error => {
            self.$emit('alert', { code : 2, text : error.response.data.error_description});
            console.log(error.response.data)
          })




        // console.log(payload)

      },

      getAppointmentItems() {

        const self = this

        this.$http.get(this.$apiUrl + 'checkups/' + this.appointment.checkup.id + '/appointment/items')
          .then(response => {
            this.items = response.data
          })
          .catch(error => {
            this.times = []
            console.log(error)
          })
      },

      getAppointmentTimes(date) {

        const self = this

        this.$http.get(this.$apiUrl + 'checkups/' + this.appointment.checkup.id + '/appointment/schedules/' + date.substr(0, 4) + '/' + date.substr(4, 2) + '/' + date.substr(6, 2))
          .then(response => {
            this.times = self.appointmentTimeTables(response.data)
          })
          .catch(error => {
            this.times = undefined
            console.log(error)
          })
      },

      getAppointmentItemExceed(date, time) {

        const self = this

        this.selItems = []

        this.$http.get(this.$apiUrl + 'checkups/' + this.appointment.checkup.id + '/appointment/itemExceed/' + date + '/' + time.replace(':',''))
          .then(response => {

            //console.log(response.data)

            let exceedMap = new Map();

            for (let i = 0; i < response.data.length; i++) {
              exceedMap.set(response.data[i].item_id, true);
            }

            //console.log(this.items)

            for (let i = 0; i < this.items.length; i++) {
              if(exceedMap.get(this.items[i].id) !== true) this.items[i].active = false
              else this.items[i].active = true
            }
          })
          .catch(error => {
            console.log(error)
          })
      },

      appointmentTimeTables(appointmentTime) {

        if(appointmentTime == undefined) return appointmentTime

        const res = { date : appointmentTime.date, nextDate : appointmentTime.nextDate, aTimes : [] , pTimes :[]}

        for(let i = 0; i < appointmentTime.times.length; i++) {

          if(parseInt(appointmentTime.times[i].time.substr(0,2)) < 12) {
            res.aTimes.push(appointmentTime.times[i])
          } else {
            res.pTimes.push(appointmentTime.times[i])
          }
        }
        return res
      },

    },

  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>

import 'babel-polyfill'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faUserSecret, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import vueMoment from 'vue-moment'
import moment from "moment";
moment.locale("ko");


Vue.use(vueMoment, { moment });

library.add(faUserSecret, faChevronLeft, faChevronRight)

Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.config.productionTip = false
Vue.prototype.$http = axios
Vue.prototype.$apiUrl = 'https://uapi.itshealth.co.kr/api/1.0/'
//Vue.prototype.$apiUrl = 'http://127.0.0.1:8080/api/1.0/'
Vue.prototype.$imgUrl = 'https://img.itshealth.co.kr/'

export const globalStore = new Vue({
  data: {
    siDoCd: '11',
    siGunGuCds: [],
    isAll: false
  }
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
